import React, { useEffect, useState, useContext} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Box, Typography, TextField, Button } from '@mui/material';
import WechatIcon from '@mui/icons-material/Chat';
import { api, talkToLLM } from '../services/api';
import { AuthContext } from '../hooks/useAuth';

function Login() {
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const { user, login} = useContext(AuthContext); // 使用 useAuth

  const handleWechatLogin = async () => {
    // 微信登录逻辑
  };

  const sendCode = async () => {
    // 发送验证码逻辑
  };

  // useEffect(() => {
  //   if (user) {
  //     console.log('user changed, navigate to home', user);
  //     navigate('/home');
  //   }

  // }, [user])

  const handlePhoneLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!phone || !code) {
      alert('请输入手机号和验证码');
      return;
    }
    try {
      // const resp = await talkToLLM('Hello There');
      // console.log('resp from talkToLLM', resp);
      const response = await api.post('/auth/phone-login', { phone, code });
      const { token, user } = response.data;
      console.log('登录成功:', { token, user });
      login(token, user);
      navigate('/home');
    } catch (error) {
      console.error('登录失败:', error);
      alert('登录失败，请检查手机号和验证码是否正确');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
          词汇学习 AI 助手
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          startIcon={<WechatIcon />}
          onClick={handleWechatLogin}
          sx={{ mb: 2 }}
        >
          使用微信登录
        </Button>
        <form onSubmit={handlePhoneLogin} style={{ width: '100%' }}>
          <TextField
            fullWidth
            label="手机号"
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="验证码"
            variant="outlined"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            登录
          </Button>
          <Button
            fullWidth
            variant="contained"
            component={Link}
            color="secondary"
            to="/home"
            sx={{ mt: 2 }}
          >
            返回主页
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default Login;
