import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, IconButton, LinearProgress, Slide, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import { Utils } from '../../utils/utils';
import styles from './WordPanel.module.css';
import { DWord, WordStatus, WordMode } from '../../types/word';
import { ExamState, ExamStates, EXAM_MODES } from './types';
import { WordModes } from '../../types/word';
import { responsive } from '../../utils/responsive';
import ReadyGo from '../ReadyGo';

interface CountdownTimerProps {
  startTime: number;
  dueTime: number;
  onTimeUp?: () => void;
}

function CountdownTimer({
  startTime,
  dueTime,
  onTimeUp,
}: CountdownTimerProps) {
  const [remainingTime, setRemainingTime] = useState(dueTime);
  const animationFrameRef = useRef<number | null>(null);

  const updateTimer = useCallback(() => {
    if (startTime === 0 || dueTime === 0 || dueTime <= startTime) return;

    const newRemainingTime = Math.max(dueTime - Date.now(), 0);

    setRemainingTime(newRemainingTime);

    if (newRemainingTime > 0) {
      animationFrameRef.current = requestAnimationFrame(updateTimer);
    } else {
      onTimeUp?.(); 
    }
  }, [startTime, dueTime, onTimeUp]);

  useEffect(() => {
    if (dueTime > 0 && startTime > 0 && dueTime > startTime) {
      animationFrameRef.current = requestAnimationFrame(updateTimer);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [dueTime, startTime, updateTimer]);

  const totalTime = dueTime - startTime;

  // 计算进度条颜色
  let progressColor;
  if (remainingTime > totalTime / 2) {
    progressColor = `rgb(0,255,0)`;
  } else {
    let progress = 1 - (remainingTime * 2) / totalTime;
    let g = 0 + (255 - 0) * progress;
    let r = 255 - (255 - 0) * progress;
    // 调整橙色过渡部分
    if (progress < 0.3) {
      g = 255 - (255 - 128) * (progress / 0.3);
      r = 0 + (255 - 0) * (progress / 0.3);
    } else {
      g = 128 - (128 - 0) * ((progress - 0.3) / 0.7);
      r = 255 - (255 - 255) * ((progress - 0.3) / 0.7);
    }
    progressColor = `rgb(${r},${g},0)`;
  }
  return (
    <Box className={styles.countdown} sx={{ paddingRight: responsive(8) }}>
      <CircularProgress
        key={remainingTime}
        variant="determinate"
        value={(remainingTime / totalTime) * 100}
        size={responsive(28)}
        thickness={5}
        sx={{ color: progressColor }}
      />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ fontSize: responsive(12), fontWeight: "bold" }}
        >
          {remainingTime > 0 ? `${Math.ceil(remainingTime / 1000)}` : "\u00A0"}
        </Typography>
      </Box>
    </Box>
  );
}

interface WordPanelProps {
  title?: string;
  state: ExamState;
  wordInfo?: DWord;
  mode: WordMode;
  current: number;
  total: number;
  isPlaying: boolean;
  spelledWord?: string;
  status?: WordStatus;
  showAnswer?: boolean;
  startTime?: number;
  dueTime?: number;
  showBack?: boolean;

  setSpelledWord?: (word: string) => void;
  onTimeUp?: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
  onBack?: () => void;
  onReadyGo?: () => void;
}

function WordPanel({ 
  title='',
  state,
  wordInfo, 
  mode,
  current,
  total,
  isPlaying,
  spelledWord = '',
  status = 'none',
  showAnswer = false,
  startTime = 0,
  dueTime = 0,
  onTimeUp,
  onPrevious,
  onNext,
  onBack,
  onReadyGo,
  showBack = false,
}: WordPanelProps) {
  const [showControls, setShowControls] = useState<boolean>(false);
  const [showHint, setShowHint] = useState<boolean>(true);


  const handleAreaClick = (area: string) => {
    console.log(`[word panel] ${area} clicked`);
    if (area === 'left') {
      onPrevious?.();
    } else if (area === 'right') {
      onNext?.();
    }
  };

  const progress = ((current + 1) / total) * 100;

  const renderModeHint = () => {
    if (mode === WordModes.BROWSE) {
      return <></>;
    }
    let statusClass = ''; 
    let statusHint;
    if (status === 'success') {
      statusClass=styles.pass;
      statusHint=(
          <CheckIcon/>
      )
    } else if (status === 'failure') {
      statusClass=styles.fail;
    }
    let hintLabel;
    let modeClass;
    switch (mode) {
      case WordModes.EXAM_E2C:
        hintLabel = '说含义';
        modeClass = styles.modeHintE2C;
        break;
      case WordModes.EXAM_C2E:
        hintLabel = '说单词';
        modeClass = styles.modeHintC2E;
        break;
      case WordModes.EXAM_SPELL:
      default:
        hintLabel = '请拼写';
        modeClass = styles.modeHintSpell;
        break;
    } 

    return (
      <Box className={`${styles.modeHint} ${modeClass} ${statusClass}`} sx={{
        borderRadius: responsive(10),
        borderWidth: responsive(2),
        borderStyle: 'solid',
        fontSize: responsive(16),
        padding: `${responsive(4)} ${responsive(8)}`,
        marginBottom: responsive(8),
        height: responsive(24),
        width: responsive(48),
      }}>
        {statusHint? statusHint: hintLabel}
      </Box>
    )
  };

  const renderReadyGo = () => {
    if (state !== ExamStates.READY) {
      return <></>;
    }
    return (
      <ReadyGo interval={500} start={1} onTimeUp={onReadyGo}/>
    );
  };


  const renderWordDisplay = () => {
    if (!wordInfo) {
      return <></>;
    }
    const underlineColor = mode === WordModes.EXAM_C2E ? 'black' : 'slateblue';

    const replaceSpace = (str: string): string => {
      return str.replace(' ', "\u00A0");
    }

    const renderLetter = (word: string, letter: string, index: number) => {
      let actualLetter = '\u200B'; 
      let userProvided = false;
      let hightlight = false;
      if (showAnswer || (showHint && (index === 0 || index === word.length - 1))) {
        actualLetter = letter;
        if (spelledWord === word) {
          hightlight = true;
        }
      } 
      if (spelledWord.length > index && !showAnswer && !hightlight) {
        actualLetter = spelledWord[index];
        userProvided = true;
      }
      return (
        <span
          key={index}
          style={{
            display: "inline-block",
            textAlign: "center",
            justifyContent: "center",
            width: "0.5em",
            margin: "0 0.08em",
            color: userProvided ? 'darkblue' : hightlight? 'darkgreen':'inherit',
          }}
        >
          {replaceSpace(actualLetter)}
          <span
            style={{
              display: "block",
              borderBottom: `3px solid ${underlineColor}`,
              width: "100%",
            }}
          ></span>
        </span>
      );
    }

    const renderWord= (word: string, alternative = false, highlight = false) => {
      const className= alternative ? styles.alternative : '';
      if (mode === WordModes.EXAM_C2E || mode === WordModes.EXAM_SPELL) {
        return (
          <span>
            {word.split("").map((letter, index) => {
              return renderLetter(word, letter, index);
            })}
          </span>
        );
      } else {
        return (
          <span className={className}>
            {replaceSpace(word)}
          </span>
        );
      }
    }

    return (
      <Typography variant="h1" gutterBottom sx={{
        fontFamily: "'Comic Sans', 'Comic Sans MS', 'Marker Felt', 'Arial'",
        fontWeight: 'bold',
        fontSize: `clamp(2rem, 8vw, 7rem)`}}>
        {renderWord(wordInfo.word, false)}
        {wordInfo.alternative && (
          <Typography component="span" className={styles.alternative} sx={{
            fontFamily: "inherit",
            fontWeight: "inherit",
            fontSize: `clamp(1.4rem, 4.8vw, 4.2rem)`,
            whiteSpace: 'nowrap',
          }}>
             <span style={{fontFamily: "Arial"}}> / </span> 
            {renderWord(wordInfo.alternative, true)}
          </Typography>
        )
        }
      </Typography>
    );
  };

  const renderStatusBar = () => {
    return (
      <Box className={styles.statusBar} sx={{fontSize: responsive(12)}}>
        <Box className={styles.statusBarLeft}>
          {/* 左侧区域 */}
          {showBack && (
            <IconButton
              onClick={onBack}
              aria-label="返回"
            >
              <ArrowBackIcon sx={{ width: responsive(24), height: responsive(24) }} />
            </IconButton>
          )}
        </Box>

        <Box className={styles.statusBarCenter}>{title}</Box>

        <Box className={styles.statusBarRight}>
          {/* 右侧区域 - 倒计时进度条 */}
          {isPlaying && (
            <CountdownTimer startTime={startTime} dueTime={dueTime} onTimeUp={onTimeUp} />
          )}
        </Box>
      </Box>
    );
  }

  const renderPronunciationBox = () => {
    if (!wordInfo) {
      return <></>;
    }
    let showPronunciation = true;
    if ((mode === WordModes.EXAM_SPELL || mode === WordModes.EXAM_C2E) && !showAnswer) {
        showPronunciation = false;
    }

    const pronunciationItem = (label: string, item: string) => {
      return (
        <Box display="flex" alignItems="center" sx={{
          whiteSpace: 'nowrap',
        }}>
          {showPronunciation && item && (
            <Typography
              variant="body1"
              component="span"
              className={styles.pronunciationLabel}
              display="flex"
              alignItems="center"
              sx={{
                fontSize: responsive(12),
                borderRadius: responsive(6),
                padding: `${responsive(4)} ${responsive(4)}`,
                height: responsive(12),
                width: responsive(12),
              }}
            >
              {label}
            </Typography>
          )}
          <Typography
            variant="body1"
            component="span"
            className={styles.pronunciationText}
          >
            {showPronunciation && item ? `/${item}/` : "\u00A0"}
          </Typography>
        </Box>
      );
    }
    return (
      <Box className={styles.pronunciationBox} sx={{
        whiteSpace: 'wrap',
      }}>
        {showPronunciation && wordInfo.usPhone && pronunciationItem('美', wordInfo.usPhone)}
        {' '}
        {showPronunciation && wordInfo.ukPhone && pronunciationItem('英', wordInfo.ukPhone)}
      </Box>
    );
  };

  const renderTranslationBox = () => {
    if (!wordInfo) {
      return <></>;
    }
    let showTranslation = true;
    if (mode === WordModes.EXAM_E2C && !showAnswer) {
        showTranslation = false;
    }
    return (
      <Box className={styles.translationBox}>
        <Box
          className={styles.translationInnerBox}
          sx={{
            padding: responsive(16),
            borderRadius: responsive(16),
          }}
        >
          {showTranslation &&
            Utils.parseTranslation(wordInfo.translation).map(
              ([partOfSpeech, explanation], index) => (
                <Box key={index} className={styles.translationEntry}>
                  <Typography
                    variant="body1"
                    component="span"
                    className={styles.partOfSpeech}
                    sx={{
                      fontSize: responsive(14),
                      minWidth: responsive(24),
                      paddingRight: responsive(4),
                    }}
                  >
                    {partOfSpeech || "\u00A0"}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className={styles.translationText}
                    sx={{ fontSize: responsive(14) }}
                  >
                    {explanation}
                  </Typography>
                </Box>
              )
            )}
        </Box>
      </Box>
    );
  } 

  // log all states and props with names  
  // console.log(
  //   `WordPanel rerender: wordInfo: ${wordInfo?.word}, \n\t current: ${current}, \n\t total: ${total}, \n\t isPlaying: ${isPlaying}, \n\t  showAnswer: ${showAnswer}`
  // );
  return (
    <Box className={styles.container}>
      {renderStatusBar()}
      <Box className={styles.contentBox}>
        {renderReadyGo()}
        {wordInfo && (
          <>
            {renderModeHint()}
            {renderWordDisplay()}
            {renderPronunciationBox()}
            {renderTranslationBox()}
          </>
        )}
      </Box>
      <Box className={styles.clickArea}>
        <Box
          className={`${styles.clickPad} ${styles.clickPadLeft}`}
          id="leftClickArea"
          onClick={() => handleAreaClick("left")}
        />
        <Box
          className={`${styles.clickPad} ${styles.clickPadCenter}`}
          id="centerClickArea"
          onClick={() => handleAreaClick("center")}
        />
        <Box
          className={`${styles.clickPad} ${styles.clickPadRight}`}
          id="rightClickArea"
          onClick={() => handleAreaClick("right")}
        />
      </Box>
      <Slide direction="up" in={showControls} mountOnEnter unmountOnExit>
        <Box className={styles.controlBar}>
          <IconButton
            onClick={() => setShowControls(!showControls)}
            className={styles.playPauseButton}
          >
            <PlayArrowIcon />
          </IconButton>
        </Box>
      </Slide>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          width: "100%",
          height: responsive(6),
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      />
    </Box>
  );
}

export default WordPanel;
