import { WordModes } from '../../types/word';

export const EXAM_MODES = {
  BROWSE: WordModes.BROWSE,  
  SPELL: WordModes.EXAM_SPELL,
  C2E: WordModes.EXAM_C2E,
  E2C: WordModes.EXAM_E2C,
  MIXED: 'mixed'
}

export const ExamStates = {
  INIT: 'init',
  READY: 'ready',
  PLAYING: 'playing',
  PAUSED: 'paused',
  FINISHED: 'finished'
}

export type ExamState = typeof ExamStates[keyof typeof ExamStates];
