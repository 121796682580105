import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Button } from '@mui/material';
import { getCollectionWords } from '../services/api';
import useSpeechRecognition, { SpeechResult } from '../hooks/useSpeechRecognition';
import styles from '../styles/global.css';


function BrowseCollection() {
  const { id } = useParams();
  const [words, setWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const { transcript, startListening, stopListening } = useSpeechRecognition(
    (result: SpeechResult) => handleVoiceCommand(result)
  );

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const response = await getCollectionWords(id as string);
        setWords(response.data);
      } catch (error) {
        console.error('获取单词列表失败:', error);
      }
    };
    fetchWords();
  }, [id]);

  useEffect(() => {
    startListening('en-US');
    return () => stopListening();
  }, []);

  const handleVoiceCommand = useCallback((result: SpeechResult) => {
    const command = result.transcript.toLowerCase().trim();
    switch (command) {
      case 'next':
      case 'next word':
        setCurrentIndex(prev => (prev + 1) % words.length);
        break;
      case 'previous':
      case 'previous word':
        setCurrentIndex(prev => (prev - 1 + words.length) % words.length);
        break;
      case 'pause':
        setIsPlaying(false);
        break;
      case 'resume':
      case 'play':
        setIsPlaying(true);
        break;
      default:
        // 检查拼写和释义
        checkSpellingAndMeaning(command);
    }
  }, [words]);

  const checkSpellingAndMeaning = (spoken: string) => {
    const currentWord = words[currentIndex];
    console.log('check spoken', spoken, currentIndex, currentWord);
    if (!currentWord) {
      return;
    }
    // @ts-ignore
    if (spoken === currentWord.word.toLowerCase()) {
      console.log('拼写正确！');
      // 这里可以添加一些视觉反馈
    // @ts-ignore
    } else if (spoken.includes(currentWord.translation.toLowerCase())) {
      console.log('释义正确！');
      // 这里可以添加一些视觉反馈
    }
  };

  if (!words || words.length === 0) {
    return <Typography>加载中...</Typography>;
  }

  return (
    <Container maxWidth="xl" disableGutters>
      {/* <WordPanel 
      /> */}
    </Container>
  );
}

export default BrowseCollection;
