"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluateFontSize = exports.responsive = exports.rem = exports.vw = void 0;
const BASE_FONT_SIZE = 16; // 基准字体大小
const BASE_SCREEN_WIDTH = 375; // 基准屏幕宽度（iPhone SE）
const BASE_SCREEN_HEIGHT = 667; // 基准屏幕高度（iPhone SE）
const vw = (px) => {
    return `${(px / BASE_SCREEN_WIDTH) * 100}vw`;
};
exports.vw = vw;
const rem = (px) => {
    return `${px / BASE_FONT_SIZE}rem`;
};
exports.rem = rem;
// 响应式缩放函数
const responsive = (px) => {
    return `clamp(${(0, exports.rem)(px * 0.8)}, ${(0, exports.vw)(px)}, ${(0, exports.rem)(px * 1.4)})`;
};
exports.responsive = responsive;
const evaluateFontSize = (baseFontSize) => {
    // get viewport width, treat as vertical screen
    const standardScale = BASE_SCREEN_HEIGHT / BASE_SCREEN_WIDTH;
    const viewportWidth = window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth;
    const viewportHeight = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight;
    const scale = viewportHeight / standardScale;
    const standardHeight = scale > standardScale ? viewportWidth * standardScale : viewportHeight;
    let fontSize = 0;
    if (viewportWidth > viewportHeight) {
        fontSize = standardHeight / BASE_SCREEN_HEIGHT * BASE_FONT_SIZE * 0.6;
    }
    else {
        fontSize = standardHeight / BASE_SCREEN_WIDTH * BASE_FONT_SIZE * 0.6;
    }
    console.log("***************** evaluated font size:", fontSize);
    return fontSize;
};
exports.evaluateFontSize = evaluateFontSize;
