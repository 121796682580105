import { useState, useEffect, createContext } from 'react';
import React from 'react';
import { DUser } from '@project/common';


export const AuthContext = createContext<{
  isAuthenticated: boolean;
  user: DUser | null;
  login: (token: string, user: DUser) => void;
  logout: () => void;
  checkAuth: () => boolean;
}>({ isAuthenticated: false, user: null, login: () => {}, logout: () => {}, checkAuth: () => false });

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<DUser| null>(null);

  useEffect(() => {
    const {isAuthenticated, user} = checkAuthInner();
    setIsAuthenticated(isAuthenticated);
    setUser(user);
  }, []);


  const login = (token: string, user: DUser) => {
    localStorage.setItem('token', token);
    localStorage.setItem("user", JSON.stringify(user));
    setIsAuthenticated(true);
    setUser(user);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);
  };

  const checkAuthInner = (): {isAuthenticated: boolean, user: DUser | null, token: string | null} => {
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    try {
      if (token && storedUser) {
        const user = JSON.parse(storedUser);
        if (user && typeof user === 'object') {
          return {
            isAuthenticated: true,
            user: user,
            token: token
          }
        }
      }
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      console.log("useAuth useEffect - 用户未认证");
    } catch (error) {
      console.error("Error parsing user from localStorage:", error);
      console.log("useAuth useEffect - 用户未认证");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    return {
      isAuthenticated: false,
      user: null,
      token: null
    }
  }

  const checkAuth = (): boolean => {
    const {isAuthenticated} = checkAuthInner();
    return isAuthenticated;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

// export function useAuth() {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     const storedUser = localStorage.getItem('user');
//     console.log('useAuth useEffect - token:', token);
//     console.log('useAuth useEffect - storedUser:', storedUser);
//     if (token && storedUser) {
//       setIsAuthenticated(true);
//       setUser(JSON.parse(storedUser));
//       console.log('useAuth useEffect - 用户已认证');
//     } else {
//       setIsAuthenticated(false);
//       setUser(null);
//       console.log('useAuth useEffect - 用户未认证');
//     }
//     setLoading(false);
//   }, []);


//   const checkAuth = () => {
//     const token = localStorage.getItem('token');
//     const storedUser = localStorage.getItem('user');
//     if (token && storedUser) {
//       setIsAuthenticated(true);
//       setUser(JSON.parse(storedUser));
//     } else {
//       setIsAuthenticated(false);
//       setUser(null);
//     }
//   };

//   return { isAuthenticated, user, setIsAuthenticated, setUser, login, logout, checkAuth, loading };
// }
