import React, { useState, useCallback, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface HierachicalSelectionProps<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  getItemId: (item: T) => string;
  hasChildren: (item: T) => boolean;
  getChildren: (item: T) => Promise<T[]>;
  selectedIds?: Set<string>;
  onSelectionChange?: (selectedIds: Set<string>) => void;
}

function HierachicalSelection<T>({
  items,
  renderItem,
  getItemId,
  hasChildren,
  getChildren,
  selectedIds: initialSelectedIds = new Set(),
  onSelectionChange,
}: HierachicalSelectionProps<T>) {
  const [selectedIds, setSelectedIds] = useState<Set<string>>(initialSelectedIds);
  // add element stack to record the path of the selected items
  const [elementStack, setElementStack] = useState<T[][]>([items]);

  useEffect(() => {
    setElementStack([items]);
    console.log('items changed, update element stack', elementStack);
  }, [items]);

  const handleToggle = useCallback((item: T) => {
    const itemId = getItemId(item);
    const newSelectedIds = new Set(selectedIds);
    
    if (newSelectedIds.has(itemId)) {
      newSelectedIds.delete(itemId);
    } else {
      newSelectedIds.add(itemId);
    }
    
    setSelectedIds(newSelectedIds);
    onSelectionChange?.(newSelectedIds);
  }, [selectedIds, getItemId, onSelectionChange]);

  const handleBackClick = useCallback(() => {
    setElementStack(prev => prev.slice(0, -1));
  }, []);

  const handleChildrenClick =useCallback(async (item: T) => {
    const children = await getChildren(item);
    console.log("got children: ", children);
    setElementStack(prev => [...prev, children]);
  }, [getChildren]);

  const currentItems = elementStack[elementStack.length - 1];
  console.log("before render current items: ", elementStack.length, currentItems);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {elementStack.length > 1 && (
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBackClick} edge="start">
            <ArrowBackIcon />
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              返回上级
            </Typography>
          </IconButton>
        </Box>
      )}
      
      <List sx={{ width: '100%' }}>
        {currentItems.map((item) => {
          const itemId = getItemId(item);
          const labelId = `checkbox-list-label-${itemId}`;

          return (
            <ListItem
              key={itemId}
              secondaryAction={
                hasChildren(item) && (
                  <IconButton edge="end" onClick={() => {handleChildrenClick(item)}}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                )
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedIds.has(itemId)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    onChange={() => handleToggle(item)}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={renderItem(item)} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default HierachicalSelection; 