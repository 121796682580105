import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Utils } from "../utils/utils";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { getCollectionDetails, createLearningPlan } from "../services/api";
import AppLayout from "../components/AppLayout";
import { DCollection, PlanSuggestion, DPlan } from "@project/common";
import SelectionCardList from "../components/SelectionCardList";
import CollectionSelectionDialog from "../components/CollectionSelectionDialog";

const DEFAULT_PLAN_NAME = "我的学习计划";
function CreateLearningPlan() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [collections, setCollections] = useState<DCollection[]>([]);
  const [planName, setPlanName] = useState(DEFAULT_PLAN_NAME);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [durationDays, setDurationDays] = useState(10);
  const [wordsPerStage, setWordsPerStage] = useState(30);
  const [suggestion, setSuggestion] = useState<PlanSuggestion | null>(null);
  const [plan, setPlan] = useState<DPlan | null>(null);
  const [collectionDialogOpen, setCollectionDialogOpen] = useState(false);

  useEffect(() => {
    const fetchCollectionDetails = async () => {
      try {
        const collectionId =
          id || new URLSearchParams(location.search).get("collectionId");
        if (collectionId) {
          const response = await getCollectionDetails(collectionId);
          setCollections([response.data]);
          setPlanName(`${response.data.name}的学习计划`);
        }
      } catch (error) {
        console.error("获取词库详情失败:", error);
      }
    };
    fetchCollectionDetails();
  }, [id, location]);

  const handleConfig = () => {
    setSuggestion(null);
  }

  const composePlanConfig = (justSuggestion: boolean = false) => {
    console.log("composePlanConfig", justSuggestion, suggestion);
    console.log("sub plans", suggestion?.subPlans?.[0]);
    const planConfig = {
      name: planName,
      collectionIds: collections.map((c) => c._id),
      suggestion: justSuggestion? true : false,
      suggested: justSuggestion? undefined : suggestion,
      strategy: {
        startDate: startDate,
        durationDays: durationDays,
        dueDate: new Date(startDate.getTime() + durationDays * 24 * 60 * 60 * 1000),
        wordsPerStage: wordsPerStage,
      },
    };
    return planConfig;
  };

  const handleCreateSuggestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await createLearningPlan(composePlanConfig(true));
      const ps: PlanSuggestion = response.data as PlanSuggestion;
      setSuggestion(ps);
    } catch (error) {
      console.error("创建学习计划失败:", error);
    }
  };

  const handleCreate = async () => {
    try {
      const response = await createLearningPlan(composePlanConfig(false));
      setPlan(response.data as DPlan);
    } catch (error) {
      console.error("创建学习计划失败:", error);
    }
  };

  const handlePlanDetail = () => {
    navigate(`/plan/${plan?._id}`);
  }

  const handleCollectionAdd = () => {
    setCollectionDialogOpen(true);
  };
  const handleCollectionDialogClose = () => {
    setCollectionDialogOpen(false);
  };
  const handleCollectionDialogConfirm = (selected: DCollection[]) => {
    setCollections(selected);
    if (planName === DEFAULT_PLAN_NAME) {
      setPlanName(selected.length > 0 ? selected[0].name + "的学习计划" : DEFAULT_PLAN_NAME);
    }
    setCollectionDialogOpen(false);
  };
  const handleCollectionDelete = (c: any) => {
    const collection = c as DCollection;
    setCollections((prevCollections) =>
      prevCollections.filter((c) => c._id !== collection._id)
    );
  };
  const renderCollectionSelectionCard = (c: any) => {
    const collection = c as DCollection;
    return <Typography>{collection.name}</Typography>;
  };

  const renderConfigPage = () => {
    return (
      <>
        <Typography variant="h4" component="h1" gutterBottom>
          创建学习计划
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          选择词库
        </Typography>
        <SelectionCardList
          items={collections}
          onAdd={handleCollectionAdd}
          onDelete={handleCollectionDelete}
          renderItem={renderCollectionSelectionCard}
        />
        <Box component="form" onSubmit={handleCreateSuggestion} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="计划名称"
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            type="number"
            label="计划时长"
            value={durationDays}
            onChange={(e) => setDurationDays(parseInt(e.target.value) || 1)}
            margin="normal"
            required
            inputProps={{
              min: 1,
              max: 365,
              step: 1,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">天</InputAdornment>,
            }}
          />
          <Box sx={{ mt: 2, width: "100%" }}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              单次最大词数
            </Typography>
            <ToggleButtonGroup
              value={wordsPerStage}
              onChange={(e, value) => setWordsPerStage(value)}
              exclusive={true}
              fullWidth={true}
            >
              <ToggleButton value={5}>5</ToggleButton>
              <ToggleButton value={10}>10</ToggleButton>
              <ToggleButton value={20}>20</ToggleButton>
              <ToggleButton value={30}>30</ToggleButton>
              <ToggleButton value={50}>50</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            sx={{
              mt: 2,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              查看建议的学习计划
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const renderSuggestionPage = () => {
    console.log("start render suggestion", suggestion);
    // show plan suggestion details
    return (
      <>
        <Typography variant="h5" component="h1" gutterBottom>
          建议的学习计划：{suggestion?.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {suggestion?.description}
        </Typography>
        <Typography variant="body1" gutterBottom>
          计划时间: {Utils.formatDate(suggestion?.startDate)} -{" "}
          {Utils.formatDate(suggestion?.dueDate)}
        </Typography>
        <List>
          {suggestion?.subPlans?.map((subPlan) => (
            <ListItem key={subPlan.name}>
              <ListItemText
                primary={subPlan.name}
                secondary={
                  <>
                    <Typography variant="body2">{`${subPlan.wordCount}个单词`}</Typography>
                    <Typography variant="body2">{`${Utils.formatDate(subPlan.startDate
                    )} - ${Utils.formatDate(subPlan.dueDate)}`}</Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: 2, display: "flex", gap: 2, justifyContent: "center" }}>
          <Button variant="contained" color="secondary" onClick={handleConfig} sx={{minWidth: "8rem"}}>
            返回修改
          </Button>
          <Button variant="contained" color="primary" onClick={handleCreate} sx={{minWidth: "8rem"}}>
            创建计划
          </Button>
        </Box>
      </>
    );
  };

  const renderSuccessPage = () => {
    return (
      <>
        <Typography variant="h5" component="h1" gutterBottom>
          创建成功：{plan?.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {suggestion?.description}
        </Typography>
        <Typography variant="body1" gutterBottom>
          计划时间: {Utils.formatDate(suggestion?.startDate)} -{" "}
          {Utils.formatDate(suggestion?.dueDate)}
        </Typography>
        <Typography variant="body1" gutterBottom>
          子计划数: {suggestion?.subPlans?.length}
        </Typography>
        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <Button variant="contained" color="primary" onClick={handlePlanDetail}>
            查看计划
          </Button>
        </Box>
      </>
    );
  };

  return (
    <AppLayout title="创建学习计划" showBackButton={true} backPath="/plan">
      <Container maxWidth="md" sx={{ mt: 4 }}>
        {plan ? renderSuccessPage() : suggestion ? renderSuggestionPage() : renderConfigPage()}
      </Container>
      <CollectionSelectionDialog
        open={collectionDialogOpen}
        initialSelectedCollections={collections}
        onClose={handleCollectionDialogClose}
        onConfirm={handleCollectionDialogConfirm}
      />
    </AppLayout>
  );
}

export default CreateLearningPlan;
