import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Card, CardContent, CardActionArea, Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { getTasks } from '../services/api';
import AppLayout from '../components/AppLayout';
import { DTask } from '@project/common';
import { Utils } from "../utils/utils";
import { responsive } from '../utils/responsive';
import { useNavigate } from 'react-router-dom';


function TaskList() {
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await getTasks();
        setTasks(response.data);
      } catch (error) {
        console.error('获取任务列表失败:', error);
      }
    };
    fetchTasks();
  }, []);

  const handleCompleteTask = (task: DTask) => {
    console.log('complete task', task);
    switch (task.mode) {
      case 'browse':
        navigate(`/plan/browse/${task.plan}?mode=browse`);
        break;
      case 'mixed':
      case 'e2c':
      case 'c2e':
      case 'spell':
        navigate(`/plan/exam/${task.plan}?mode=${task.mode}`);
        break;
      default:
        console.log('unknown task type', task.mode);
    }
  };

  return (
    <AppLayout title="任务列表">
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          {tasks.map((task: DTask) => (
            <Grid size={{xs: 12, sm: 12, md: 12}} key={task._id}>
              <Card>
                <CardActionArea
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft: responsive(10),
                    paddingRight: responsive(10),
                  }}
                >
                  <CardContent>
                    <Typography
                      component="div"
                      variant="h6"
                      color="text.primary"
                    >
                      {task.name}
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="text.secondary"
                    >
                      {"计划完成：" + Utils.formatDate(task.dueDate)}
                    </Typography>
                  </CardContent>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      onClick={() => handleCompleteTask(task)}
                      variant="contained"
                      color="primary"
                    >
                      去完成
                    </Button>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </AppLayout>
  );
}

export default TaskList;
