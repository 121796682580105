
import { talkToLLM, judgeChineseMeaning } from './api';


// 创建 aiService 对象
const aiService = {
  async judgeChineseMeaning(word, reference, provided) {
    try {
      // 调用服务端的 judgeChineseMeaning 接口
      const response = await judgeChineseMeaning(word, reference, provided);
      return response.data;
    } catch (error) {
      console.error('调用 AI 服务失败:', error);
      return false;
    }
  },
  // 检查中文释义是否匹配
  async checkChineseTranslation(word, provided) {
    // 组织 prompt 模板
    const prompt = `
    请判断用户提供的释义是否正确，回答 true 或 false
    单词： ${word.word}
    参考释义：${word.translation.replace(/\n/g, '')}
    用户提供：${provided}

    请注意，用户提供内容为语音识别而来，很容易识别成相似发音的其他字（比如梨和李），如果遇到这种情况，尽量认为是正确的
    `;

    console.log(prompt);
    try {
      // 调用服务端的 talk 接口
      const response = await talkToLLM(prompt);

      console.log('res from llm', response.data);
      // 返回服务端的响应
      if (response.data.reply.includes('true')) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('调用 AI 服务失败:', error);
      throw error; // 抛出错误以便调用者处理
    } finally {
      return false;
    }
  },
};

export default aiService;
