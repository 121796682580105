import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Button,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import { getPlanDetails, getWordProficiency, deletePlan } from "../services/api";
import AppLayout from '../components/AppLayout';
import WordList from '../components/WordList';
import { DPlan, DWordProficiency, DWord } from '@project/common';
import { responsive } from '../utils/responsive';
import ConfirmDialog from '../components/ConfirmDialog';

function PlanDetails() {
  const { id } = useParams();
  const [plan, setPlan] = useState<DPlan | null>(null);
  const [proficiencies, setProficiencies] = useState<Record<
    string,
    DWordProficiency
  > | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [modeDialogOpen, setModeDialogOpen] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const backpath = searchParams.get('backpath') || location.state?.backpath || '/plan';

  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        const response = await getPlanDetails(id as string);
        console.log('plan details', response.data);
        setPlan(response.data);
        if (response.data.words) {
          const data = await getWordProficiency(
            response.data.words.map((word: DWord) => word.word)
          );
          console.log('proficiencies', data.data);
          setProficiencies(data.data);
        }
      } catch (error) {
        console.error("获取学习计划详情失败:", error);
      }
    };
    fetchPlanDetails();
  }, [id]);

  const renderSubPlans = useCallback(() => {
    if (!plan || !plan.subPlans || plan.subPlans.length === 0) {
      return <></>;
    }
    return (
      <>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" component="h2" gutterBottom>
          子计划
        </Typography>
        <List>
          {plan.subPlans.map((subPlan) => (
            <ListItemButton
              component={Link}
              to={`/plan/${subPlan._id}?backpath=/plan/${plan._id}`}
              key={subPlan._id}
            >
              <ListItemText
                primary={subPlan.name}
                secondary={subPlan.description}
              />
            </ListItemButton>
          ))}
        </List>
      </>
    );
  }, [plan, proficiencies]);

  const renderWordList = useCallback(() => {
    if (!plan || !plan.words || plan.words.length === 0) {
      return <></>;
    }
    return (
      <>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" component="h2" gutterBottom>
          单词列表
        </Typography>
        <WordList wordList={plan.words} proficiencies={proficiencies} />
      </>
    );
  }, [plan, proficiencies]);  

  const handleDelete = async () => {
    try {
      await deletePlan(id as string);
      navigate(backpath);
    } catch (error) {
      console.error("删除学习计划失败:", error);
    }
  };


  const renderPrimaryPlanSummary = useCallback(() => {

    let masteredWords = 0, progress = 0;
    if (plan?.words && proficiencies) {
      masteredWords = plan.words.filter(
        (word: DWord) => proficiencies?.[word.word]?.proficiency > 0.6
      ).length;

      progress = Math.round(plan.isPrimary
        ? plan.subPlans.filter((subPlan: DPlan) => subPlan.finishedAt).length /
          plan.subPlans.length * 100
        : (masteredWords / (plan?.words.length || 1)) * 100);
    }
    return (
      <>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">总单词数：{plan?.words.length || 0}</Typography>
          <Typography variant="body1">
            已掌握单词数： {masteredWords}
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">学习进度：{progress}%</Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ mt: 1, height: responsive(8), borderRadius: responsive(4) }}
          />
        </Box>
      </>
    );
  }, [plan, proficiencies]);

  const handleStartExam = () => {
    setModeDialogOpen(true);
  };

  const handleModeSelect = (mode: string) => {
    setModeDialogOpen(false);
    navigate(`/plan/exam/${(plan as DPlan)._id}?mode=${mode}&backpath=/plan/${(plan as DPlan)._id}`);
  };

  console.log("render Plan details", id);
  if (!plan) {
    return <Typography>加载中...</Typography>;
  }

  return (
    <AppLayout title={plan.name} showBackButton={true} backPath={backpath}>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {plan.name}
        </Typography>
        {renderPrimaryPlanSummary()}
        <Button
          component={Link}
          to={`/plan/browse/${plan._id}?backpath=/plan/${plan._id}`}
          variant="contained"
          color="secondary"
          sx={{ mr: 2 }}
        >
          开始学习
        </Button>
        <Button
          component={Link}
          to={`/plan/exam/${plan._id}?backpath=/plan/${plan._id}`}
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            handleStartExam();
          }}
        >
          开始测试
        </Button>
        {renderSubPlans()}
        {renderWordList()}

        {plan.isPrimary && (
          <Box sx={{ mt: 4, mb: 4, padding: "20em 0 2em 0" }}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => setConfirmDialogOpen(true)}
            >
              删除学习计划
            </Button>
          </Box>
        )}

        <ConfirmDialog
          open={confirmDialogOpen}
          content={`确定要删除学习计划 "${plan.name}" 吗？此操作将同时删除所有子学习计划，且不可恢复。`}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={handleDelete}
          confirmText="删除"
          confirmColor="error"
        />

        <Dialog
          open={modeDialogOpen}
          title="选择测试模式"
          onClose={() => setModeDialogOpen(false)}
          maxWidth="xl"
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                flexWrap: "wrap",
                p: 5,
              }}
            >
              <IconButton
                onClick={() => handleModeSelect("e2c")}
                sx={{
                  width: 120,
                  height: 120,
                  borderRadius: 4,
                  flexDirection: "column",
                  border: "2px solid",
                  borderColor: "rgb(124, 76, 187)",
                  bgcolor: "rgba(124, 76, 187, 0.1)",
                  "&:hover": {
                    bgcolor: "rgba(124, 76, 187, 0.2)",
                  },
                }}
              >
                <Typography variant="h6" color="rgb(124, 76, 187)">
                  英译汉
                </Typography>
              </IconButton>

              <IconButton
                onClick={() => handleModeSelect("c2e")}
                sx={{
                  width: 120,
                  height: 120,
                  borderRadius: 4,
                  flexDirection: "column",
                  border: "2px solid",
                  borderColor: "rgb(227, 145, 38)",
                  bgcolor: "rgba(227, 145, 38, 0.1)",
                  "&:hover": {
                    bgcolor: "rgba(227, 145, 38, 0.2)",
                  },
                }}
              >
                <Typography variant="h6" color="rgb(227, 145, 38)">
                  汉译英
                </Typography>
              </IconButton>

              <IconButton
                onClick={() => handleModeSelect("spell")}
                sx={{
                  width: 120,
                  height: 120,
                  borderRadius: 4,
                  flexDirection: "column",
                  border: "2px solid",
                  borderColor: "rgb(70, 102, 204)",
                  bgcolor: "rgba(70, 102, 204, 0.1)",
                  "&:hover": {
                    bgcolor: "rgba(70, 102, 204, 0.2)",
                  },
                }}
              >
                <Typography variant="h6" color="rgb(70, 102, 204)">
                  单词拼写
                </Typography>
              </IconButton>

              <IconButton
                onClick={() => handleModeSelect("mixed")}
                sx={{
                  width: 120,
                  height: 120,
                  borderRadius: 4,
                  flexDirection: "column",
                  border: "2px solid",
                  borderColor: "grey.500",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    bgcolor: "rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <Typography variant="h6" color="text.primary">
                  混合测试
                </Typography>
              </IconButton>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </AppLayout>
  );
}

export default PlanDetails;
