import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Box,
  CssBaseline,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SchoolIcon from '@mui/icons-material/School';
import TaskIcon from '@mui/icons-material/Task';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingSpinner from './LoadingSpinner';
import styles from './AppLayout.module.css';
import { responsive } from '../utils/responsive';
import { DUser } from '@project/common';
import PersonIcon from '@mui/icons-material/Person';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { blue } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';


export interface SimpleDialogProps {
  open: boolean;
  users: DUser[];
  selectedUser: DUser;
  onClose: (value: DUser) => void;
}


function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, users, selectedUser, open } = props;

  const handleClose = () => {
    onClose(selectedUser);
  };

  const handleListItemClick = (value: string) => {
    // get matched user
    const matchedUser = users.find((user) => user.email === value);
    if (matchedUser) {
      onClose(matchedUser);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ textAlign: "center" }}>切换用户</DialogTitle>
      <List sx={{ pt: 0 }}>
        {users.map((user) => (
          <ListItem disableGutters key={user._id}>
            <ListItemButton
              onClick={() => handleListItemClick(user._id as string)}
              sx={{ padding: "0 4em" }}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.username} secondary={user.email} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disableGutters>
          <ListItemButton
            autoFocus
            onClick={() => handleListItemClick("addAccount")}
            sx={{ padding: "0 4em" }}
          >
            <ListItemAvatar>
              <Avatar>
                <SwitchAccountIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="切换其他用户" />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  );
}


interface AppLayoutProps {
  title: string;
  children: React.ReactNode;
  showBackButton?: boolean;
  backPath?: string;
  isLoading?: boolean;
}

const AppLayout = ({ title, children, showBackButton = false, backPath = '/', isLoading = false }: AppLayoutProps) => {
  const location = useLocation();
  // get current location from pathname
  const [userSelectionDialogOpen, setUserSelectionDialogOpen] = useState(false);
  const [value, setValue] = useState('home');
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const section = location.pathname.split('/')[1];
      console.log('set section', section );
      setValue(section);
    } catch (error: any) {
      setValue('home');
    }
  }, [location.pathname]);

  const handleUserSelectionDialogClose = () => {
    setUserSelectionDialogOpen(false);
  };
  const handleUserSelectionDialogOpen = () => {
    setUserSelectionDialogOpen(true);
  };

  const users: DUser[] = [
    { 
      _id: '1',
      username: 'Loki',
      email: 'loki@maotuwo.com',
      password: 'password1',
      role: 'user',
      isActive: true,
      profile: {},
      preferences: {
        language: 'en',
        emailNotifications: true,
      },
    },
    { 
      _id: '2',
      username: 'Gloria',
      email: 'gloria@maotuwo.com',
      password: 'password1',
      role: 'user',
      isActive: true,
      profile: {},
      preferences: {
        language: 'en',
        emailNotifications: true,
      },
    },
    { 
      _id: '3',
      username: 'Eric',
      email: 'eric@maotuwo.com',
      password: 'password1',
      role: 'user',
      isActive: true,
      profile: {},
      preferences: {
        language: 'en',
        emailNotifications: true,
      },
    },
    { 
      _id: '4',
      username: 'Sunny',
      email: 'sunny@maotuwo.com',
      password: 'password1',
      role: 'admin',
      isActive: true,
      profile: {},
      preferences: {
        language: 'en',
        emailNotifications: true,
      },
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/${newValue}`);
  }

  return (
    <Box className={styles.container} sx={{ padding: 0, margin: 0 }}>
      <CssBaseline />
      <AppBar position="static" className={styles.header}>
        <Toolbar>
          {showBackButton && (
            <IconButton
              edge="start"
              color="inherit"
              component={Link}
              to={backPath}
              aria-label="返回"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography
            sx={{ fontSize: responsive(12), flexGrow: 1, textAlign: "center" }}
          >
            {title}
          </Typography>
          <Avatar
            sx={{ width: responsive(32), height: responsive(32) }}
            alt="用户头像"
            src="/path/to/user-avatar.jpg"
            onClick={handleUserSelectionDialogOpen}
            component={IconButton}
          />
        </Toolbar>
      </AppBar>
      <Box component="main" className={styles.content}>
        <Box className={styles.contentArea}>{children}</Box>
        {isLoading && (
          <Box className={styles.loadingMask}>
            <LoadingSpinner />
          </Box>
        )}
      </Box>
      <Box className={styles.footer}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={handleChange}
        >
          <BottomNavigationAction
            label="主页"
            value="home"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label="词库"
            value="collection"
            icon={<ListAltIcon />}
          />
          <BottomNavigationAction
            label="计划"
            value="plan"
            icon={<SchoolIcon />}
          />
          <BottomNavigationAction
            label="任务"
            value="task"
            icon={<TaskIcon />}
          />
        </BottomNavigation>
      </Box>
      <SimpleDialog
        open={userSelectionDialogOpen}
        onClose={handleUserSelectionDialogClose}
        users={users}
        selectedUser={users[0]}
      />
    </Box>
  );
};

export default AppLayout;
