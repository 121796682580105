import React, { forwardRef, useImperativeHandle } from 'react';
import useExamController from './useExamController';
import WordPanel from './WordPanel';
import { DWord, WordModes, WordResult } from '../../types/word';

export interface WordExamRef {
  start: () => void;
  stop: () => void;
  reset: () => void;
  startWithWords: (words: DWord[]) => void;
  logStatus: () => void;
}

export interface WordExamProps {
  mode: string;
  title?: string;
  onFinished?: (results: WordResult[]) => void;
  onBack?: () => void;
  showBack?: boolean;
  resultPage?: React.ReactNode;
}

const WordExam = forwardRef<WordExamRef, WordExamProps>(({
  mode,
  title='',
  onFinished,
  onBack,
  showBack = false,
  resultPage
}, ref) => {
  const controller = useExamController(mode, onFinished);
  

  // 暴露必要的控制方法给父组件
  useImperativeHandle(ref, () => ({
    start: controller.start,
    stop: controller.stop,
    reset: controller.reset,
    startWithWords: controller.startWithWords,
    logStatus: controller.logStatus,
  }));

  return controller.isFinished() ? (
    <div>{resultPage}</div>
  ) : (
    <WordPanel
      title={title}
      state={controller.state}
      wordInfo={controller.currentWordState.wordInfo?.word}
      mode={controller.currentWordState.wordInfo?.mode || WordModes.BROWSE}
      current={controller.currentWordState.index}
      total={controller.wordSeries.length}
      isPlaying={controller.isPlaying()}
      spelledWord={controller.spelledWord}
      status={controller.wordResult}
      showAnswer={controller.showAnswer}
      startTime={controller.currentWordState.wordTime}
      dueTime={controller.showAnswer ? 0 : controller.currentWordState.wordTime + 10000}
      onReadyGo={controller.handleReadyGo}
      onTimeUp={controller.handleTimeUp}
      onPrevious={controller.handlePrevious}
      onNext={controller.handleNext}
      onBack={onBack}
      showBack={showBack}
    />
  );
});

export default WordExam; 