import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Container, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { getPlanDetails, submitExamResults } from '../services/api';
import useLoading from '../hooks/useLoading';
import LoadingSpinner from '../components/LoadingSpinner';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { WordResult, WordStatus } from '../types/word';
import { DPlan } from '@project/common';
import { EXAM_MODES } from '../components/WordExam/types';
import WordExam, { WordExamRef } from '../components/WordExam';
import WordResultBar from '../components/WordResultBar';
import './PlanExam.css'; 

function PlanExam() {
  const { id} = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [plan, setPlan] = useState<DPlan | null>(null);
  const [finished, setFinished] = useState(false);
  const [examResults, setExamResults] = useState<WordResult[]>([]);
  const backpath = searchParams.get('backpath') || location.state?.backpath || '/plan';
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const examRef = useRef<WordExamRef>(null);

  let mode = searchParams.get('mode')|| EXAM_MODES.MIXED;
  // validate mode
  if (!Object.values(EXAM_MODES).includes(mode as string)) {
    console.error('invalid exam mode', mode);
    mode = EXAM_MODES.MIXED;
  } 

  // const mode: string = EXAM_MODES.E2C;
  const handleResult = async (results: WordResult[]) => {
    setFinished(true);
    setExamResults(results);
    const result = {
      planId: id,
      results: results
    };
    console.log('handleResult', result);
    try {
      setSubmitting(true);
      await submitExamResults(result);
    } catch (error) {
      console.error('提交测试结果失败:', error);
    } finally {
      setSubmitting(false);
    }
  };


  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    showLoading();
    const fetchWords = async () => {
      try {
        const response = await getPlanDetails(id as string);
        setPlan(response.data as DPlan);
        examRef.current?.startWithWords(response.data.words);
      } catch (error) {
        console.error('获取单词列表失败:', error);
      } finally {
        hideLoading();
      }
    };
    fetchWords();
  }, [id]);


  const handleRetry = () => {
    examRef.current?.reset();
    examRef.current?.start();
  }

  const handleReturn = () => {
    console.log('handleReturn', backpath);
    navigate(backpath);
  }; 

  // console.log('render PlanExam');
  // examRef.current?.logStatus();

  const renderResultPage = () => {
    if (finished) {
      return (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <div className="status">
            {submitting ? (
              <LoadingSpinner message="结果提交中..." root={false} size={20} />
            ) : (
              ""
            )}
          </div>
          <Container className="result" >
            <Typography variant="h4">测试结果</Typography>
            <Box sx={{ height: "50dvh", overflow: "scroll" }}>
              <Grid container spacing={2} >
                {examResults &&
                  examResults.map((result, index) => (
                    <Grid size={{xs: 6, sm: 4, md: 3, lg: 2}} key={index}>
                      <WordResultBar
                        word={result.word}
                        mode={result.mode}
                        correct={result.correct}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <Typography variant="h5">
              正确率:{" "}
              {(
                (examResults.filter((r) => r.correct).length /
                  examResults.length) *
                100
              ).toFixed(2)}
              %
            </Typography>
            <Typography variant="h5">
              平均耗时:{" "}
              {(
                examResults.reduce((acc, curr) => acc + curr.timeSpent, 0) /
                examResults.length
              ).toFixed(2)}
              秒
            </Typography>
          </Container>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button variant="contained" color="primary" onClick={handleRetry}>
              再来一次
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleReturn}>
              返回
            </Button>
          </div>
        </Container>
      );
    } else {
      return <Container maxWidth="xl" disableGutters></Container>;
    }
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <WordExam
        ref={examRef}
        title={plan?.name || ''}
        mode={mode}
        showBack={true}
        onFinished={handleResult}
        onBack={handleReturn}
        resultPage={renderResultPage()}
      />
    </Container>
  );
}

export default PlanExam;
