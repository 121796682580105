import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Stack } from '@mui/material';
import AppLayout from '../components/AppLayout';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SchoolIcon from '@mui/icons-material/School';

function Home() {
  return (
    <AppLayout title="ABC Killer" showBackButton={false}>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Stack spacing={2} sx={{ mt: 4 }}>
          <Button
            component={Link}
            to="/collection"
            variant="contained"
            color="primary"
            startIcon={<ListAltIcon />}
            fullWidth
          >
            词库
          </Button>
          <Button
            component={Link}
            to="/plan"
            variant="contained"
            color="secondary"
            startIcon={<SchoolIcon />}
            fullWidth
          >
            学习计划
          </Button>
        </Stack>
      </Container>
    </AppLayout>
  );
}

export default Home;
