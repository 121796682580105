import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Card, CardContent, CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { getPrimaryCollections } from '../services/api';
import AppLayout from '../components/AppLayout';
import { DCollection } from '@project/common';

function CollectionList() {
  const [collections, setCollections] = useState<DCollection[]>([]);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await getPrimaryCollections();
        setCollections(response.data);
      } catch (error) {
        console.error('获取词库列表失败:', error);
      }
    };
    fetchCollections();
  }, []);

  return (
    <AppLayout title="词库列表">
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          {collections.map((collection: DCollection) => (
            <Grid size={{xs: 12, sm: 6, md: 4}} key={collection._id}>
              <Card>
                <CardActionArea component={Link} to={`/collection/${collection._id}`}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {collection.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {collection.description}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      单词数量: {collection.wordCount}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </AppLayout>
  );
}

export default CollectionList;
