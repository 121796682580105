import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

interface CommonDialogProps {
  open: boolean;
  title?: string;
  mode?: "normal" | "confirm" | "notice";
  onClose?: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
  hideActions?: boolean;
  confirmText?: string;
  cancelText?: string;
  confirmColor?: 'primary' | 'secondary' | 'error';
}

function CommonDialog({
  title,
  mode = "normal",
  open,
  onClose,
  onConfirm,
  children,
  confirmText,
  cancelText,
  confirmColor,
  hideActions = false,
}: CommonDialogProps) {

  const paperProps = mode === "normal" ? {
    sx: {
      m: 2,
      borderRadius: 2,
      position: "relative",
      height: { xs: "90vh", sm: "80vh" },
    },
  } : {
    sx: {
      m: 2,
      borderRadius: 2,
    },
  } ;

  return (
    <Dialog
      title={title}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={paperProps}
    >
      {title && <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>}
      {/* <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton> */}

      <DialogContent sx={{ pt: 5, pb: 2, margin: "1em" }}>
        {children}
      </DialogContent>

      {!hideActions && (
        <DialogActions sx={{ p: 2, pt: 0, gap: 2, justifyContent: "center" }}>
          <Button
            sx={{ padding: "0.5em 2em" }}
            onClick={onClose}
            variant="outlined"
            color="inherit"
          >
            {cancelText || "取消"}
          </Button>
          <Button
            sx={{ padding: "0.5em 2em" }}
            onClick={onConfirm}
            variant="contained"
            color={confirmColor || "primary"}
          >
            {confirmText || "确定"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default CommonDialog; 