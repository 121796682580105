import React, { ReactNode, Suspense, useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Home from './pages/Home';
import CollectionList from './pages/CollectionList';
import TaskList from './pages/TaskList';
import Login from './pages/Login';
import CollectionDetails from './pages/CollectionDetails';
import BrowseCollection from './pages/BrowseCollection';
import CreateLearningPlan from './pages/CreateLearningPlan';
import PlanList from './pages/PlanList';
import PlanDetails from './pages/PlanDetails';
import BrowsePlan from './pages/BrowsePlan';
import PlanExam from './pages/PlanExam';
import LoadingContext from './contexts/LoadingContext';
import LoadingSpinner from './components/LoadingSpinner';
import { responsive } from './utils/responsive';
import { AuthWrapper, AuthContext } from './hooks/useAuth';

const colorTheme = {
  cssVariables: true,
  palette: {
    primary: {
      main: '#576CDF', // 明亮的珊瑚红
    },
    secondary: {
      main: '#5CADEF', // 清新的薄荷绿
    },
    background: {
      default: '#F7F7FF', // 柔和的浅绿色背景
    },
    text: {
      primary: '#2D3E50', // 深蓝色文字，易读但不刺眼
    },
  },
};
const theme = createTheme(colorTheme, {
  typography: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    h4: {
      fontSize: responsive(24),
      fontWeight: 700,
    },
    h5: {
      fontSize: responsive(20),
    },
    button: {
      fontSize: responsive(14),
    },
    body1: {
      fontSize: responsive(16),
    },
    body2: {
      fontSize: responsive(10),
    },
  },
  // spacing: (factor: number) => responsive(factor * 8),
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: `${responsive(8)} ${responsive(16)}`,
          minHeight: responsive(32),
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: responsive(8),
          backgroundColor: colorTheme.palette.secondary.main,
          color: colorTheme.palette.text.primary
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: responsive(16),
        },
      },
    },
  },
  shape: {
    borderRadius: 12, // 增加圆角，使界面更加柔和
  },
});

const PrivateRoute = ({children}: {children: ReactNode}) => {
  const {isAuthenticated, checkAuth} = useContext(AuthContext);
  const authenticated = isAuthenticated ? true : checkAuth();
  console.log("rerender private route, isAuthenticated:", isAuthenticated, authenticated);
  return authenticated ? <>{children}</> : <Navigate to="/login" />;
};

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  console.log('app rerender');

  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <LoadingContext.Provider value={{ setIsLoading, setLoadingMessage }}>
        <AuthWrapper>
        <Router>
          {isLoading && <LoadingSpinner message={loadingMessage} />}
          <Suspense fallback={<CircularProgress />}>
            <Routes>
              <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
              <Route path="/login" element={<Login />} />
              <Route path="/home" element={<PrivateRoute><Home/></PrivateRoute>} />
              <Route path="/collection" element={<PrivateRoute><CollectionList /></PrivateRoute>} />
              <Route path="/collection/:id" element={<PrivateRoute><CollectionDetails /></PrivateRoute>} />
              <Route path="/browse/:id" element={<PrivateRoute><BrowseCollection /></PrivateRoute>} />
              <Route path="/plan/create" element={<PrivateRoute><CreateLearningPlan /></PrivateRoute>} />
              <Route path="/plan" element={<PrivateRoute><PlanList /></PrivateRoute>} />
              <Route path="/plan/:id" element={<PrivateRoute><PlanDetails /></PrivateRoute>} />
              <Route path="/plan/browse/:id" element={<PrivateRoute><BrowsePlan /></PrivateRoute>} />
              <Route path="/plan/exam/:id" element={<PrivateRoute><PlanExam /></PrivateRoute>} />
              <Route path="/task" element={<PrivateRoute><TaskList /></PrivateRoute>} />
              {/* <Route path="/task/:id" element={<PrivateRoute><TaskDetails /></PrivateRoute>} /> */}
            </Routes>
          </Suspense>
        </Router>
        </AuthWrapper>
      </LoadingContext.Provider>
    </ThemeProvider>
  );
}

export default App;
