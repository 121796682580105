import React from 'react';
import styles from './ProficiencyBar.module.css';
import { Typography } from '@mui/material';

const ProficiencyBar = ({ proficiency }: { proficiency: number }) => {
  let label = "";
  let color = "";
  if (proficiency >= 0.8) {
    label = "熟练";
    color = "green";
  } else if (proficiency >= 0.6) {
    label = "较好";
    color = "blue";
  } else if (proficiency >= 0.4) {
    label = "一般";
    color = "orange";
  } else {
    label = "生疏";
    color = "red";
  }

  return (
    <Typography
      variant="body2"
      className={`${styles.proficiencyBar} ${styles[color]}`}
      component="span"
    >
      {label}
    </Typography>
  );
};

export default ProficiencyBar;
