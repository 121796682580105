import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './ReadyGo.module.css';
import { responsive } from '../utils/responsive';

interface ReadyGoProps {
  interval: number;
  start: number;
  onTimeUp?: () => void;
}

function ReadyGo({
  interval=1000,
  start=3,
  onTimeUp,
}: ReadyGoProps) {

  const [count, setCount] = useState(start + 1);
  const countRef = useRef(start + 1);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const onInterval = useCallback(() => {
    console.log('onInterval', countRef.current);
    countRef.current = countRef.current - 1;
    setCount(countRef.current);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (countRef.current === -1) {
      onTimeUp?.();
    } else {
      setTimeout(onInterval, interval);
    }
  }, [countRef, onTimeUp, interval]);

  useEffect(() => {
    console.log('use effect in ReadyGo ', countRef.current);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(onInterval, interval);
  }, [onInterval, interval]);

  return (
    <Box sx={{
      display: 'flex',
      minHeight: '10dvh',
      minWidth: '40dvw',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography
        className={styles.readygoText}
        key={count}
        sx={{
          fontSize: count > 0 && count <= start ? '20dvw' : '12dvw',
          fontFamily: 'Arial',
          fontWeight: 800,
          fontStyle: 'italic',
          color: 'darkorange',
          textShadow: '0 0 0.2em rgba(0, 0, 0, 0.5)',
          WebkitTextStroke: '0.03em rgba(255, 255, 255, 1)',
        }}
      >
        {count > start ? 'GET READY' : count > 0 ? count : 'GO!'}
      </Typography>
    </Box>
  );
}

export default ReadyGo;
