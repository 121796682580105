import React from 'react';
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

// don't show scrollbar
const ScrollContainer = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  gap: '16px',
  padding: '8px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  borderRadius: '16px',
  border: '1px solid #e0e0e0',
  backgroundColor: '#f5f5f5',
});

const StyledCard = styled(Card)({
  minWidth: '8rem',
  position: 'relative',
  flex: '0 0 auto',
  boxShadow: '3px 3px 3px 0 rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
});

const AddCard = styled(Card)({
  minWidth: '8rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  flex: '0 0 auto',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  border: '3px dashed lightgray',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
});

interface SelectionCardListProps<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onAdd: () => void;
  onDelete: (item: T) => void;
}

function SelectionCardList<T>({ 
  items, 
  renderItem, 
  onAdd, 
  onDelete 
}: SelectionCardListProps<T>) {
  return (
    <ScrollContainer>
      <AddCard onClick={onAdd}>
        <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <AddIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
        </CardContent>
      </AddCard>
      {items.map((item, index) => (
        <StyledCard key={index}>
          <CardContent sx={{padding: '5px'}}>
            {renderItem(item)}
            <IconButton
              size="small"
              sx={{
                color: 'brown',
                position: 'absolute',
                bottom: 8,
                right: 8,
              }}
              onClick={() => onDelete(item)}
            >
              <DeleteIcon />
            </IconButton>
          </CardContent>
        </StyledCard>
      ))}
    </ScrollContainer>
  );
}

export default SelectionCardList; 