const BASE_FONT_SIZE = 16; // 基准字体大小
const BASE_SCREEN_WIDTH = 375; // 基准屏幕宽度（iPhone SE）
const BASE_SCREEN_HEIGHT = 667; // 基准屏幕高度（iPhone SE）

export const vw = (px: number): string => {
  return `${(px / BASE_SCREEN_WIDTH) * 100}vw`;
};

export const rem = (px: number): string => {
  return `${px / BASE_FONT_SIZE}rem`;
};

// 响应式缩放函数
export const responsive = (px: number): string => {
  return `clamp(${rem(px * 0.8)}, ${vw(px)}, ${rem(px * 1.4)})`;
}; 

export const evaluateFontSize = (baseFontSize: number): number => {
  // get viewport width, treat as vertical screen
  const standardScale = BASE_SCREEN_HEIGHT / BASE_SCREEN_WIDTH;
  const viewportWidth = window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth;
  const viewportHeight = window.innerWidth > window.innerHeight ? window.innerWidth: window.innerHeight;
  const scale = viewportHeight / standardScale;
  const standardHeight = scale > standardScale ? viewportWidth * standardScale : viewportHeight;
  
  let fontSize = 0;
  if (viewportWidth > viewportHeight) {
    fontSize = standardHeight / BASE_SCREEN_HEIGHT * BASE_FONT_SIZE * 0.6;
  } else {
    fontSize = standardHeight / BASE_SCREEN_WIDTH * BASE_FONT_SIZE * 0.6;
  } 

  console.log("***************** evaluated font size:", fontSize);
  return fontSize
}
