import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Button, Card, CardContent, CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import { getLearningPlans } from '../services/api';
import AppLayout from '../components/AppLayout';
import { DPlan } from '@project/common';
import { Utils } from '../utils/utils';

function PlanList() {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await getLearningPlans();
        setPlans(response.data);
      } catch (error) {
        console.error('获取学习计划列表失败:', error);
      }
    };
    fetchPlans();
  }, []);

  return (
    <AppLayout title="我的学习计划">
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Button component={Link} to="/plan/create" variant="contained" color="primary">
            创建新计划
          </Button>
        </Box>
        <Grid container spacing={3}>
          {plans.map((plan: DPlan) => (
            <Grid size={{xs: 12, sm: 6, md: 4}} key={plan._id}>
              <Card>
                <CardActionArea component={Link} to={`/plan/${plan._id}`}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {plan.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {plan.description}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      计划完成: {Utils.formatDate(plan.dueDate)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      进度: {((0 / 1) * 100).toFixed(2)}%
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </AppLayout>
  );
}

export default PlanList;
