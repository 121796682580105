import React from 'react';
import CommonDialog from './CommonDialog';
import { Typography } from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  content: string;
  onClose: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  confirmColor?: 'primary' | 'secondary' | 'error';
}

function ConfirmDialog({
  open,
  title,
  content,
  onClose,
  onConfirm,
  confirmText = '确定',
  cancelText = '取消',
  confirmColor = 'primary'
}: ConfirmDialogProps) {
  return (
    <CommonDialog
      title={title}
      mode="confirm"
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText={confirmText}
      cancelText={cancelText}
      confirmColor={confirmColor}
    >
      <Typography variant="body1">
        {content}
      </Typography>
    </CommonDialog>
  );
}

export default ConfirmDialog; 