import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const WordResultBar = ({
  word,
  mode,
  correct,
}: {
  word: string;
  mode: 'c2e' | 'e2c' | 'spell' | string;
  correct: boolean;
}) => {
  let modeLabel = "混";
  let modeColor = "";
  
  switch (mode) {
    case 'spell':
      modeLabel = "拼";
      modeColor = "rgb(70, 102, 204)";
      break;
    case 'c2e':
      modeLabel = "汉";
      modeColor = "rgb(227, 145, 38)";
      break;
    case 'e2c':
      modeLabel = "英";
      modeColor = "rgb(124, 76, 187)";
      break;
  }

  return (
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
        fontSize: '0.8em',
        alignItems: 'center',
        borderRadius: '0.4em',
        overflow: 'hidden',
        bgcolor: correct ? 'rgba(220, 255, 210, 0.8)' : 'rgba(255, 220, 220, 0.8)',
        gap: '0.2em'
      }}
    >
      <Typography
        component="span"
        sx={{
          bgcolor: modeColor,
          color: 'white',
          padding: '0.1em 0.3em',
          fontSize: '1em',
          textAlign: 'center'
        }}
      >
        {modeLabel}
      </Typography>
      
      <Typography
        component="span"
        sx={{
          color: 'text.primary',
          fontSize: '1em',
          width: '5em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {word}
      </Typography>

      {correct !== undefined && (
        <Box
          component="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: correct ? 'success.main' : 'error.main',
            paddingRight: '0.2em',
          }}
        >
          {correct ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </Box>
      )}
    </Box>
  );
};

export default WordResultBar;
