import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getCollectionDetails, getWordProficiency, deleteCollection } from "../services/api";
import WordList from "../components/WordList";
import AppLayout from "../components/AppLayout";
import { DWord, DCollection, DWordProficiency } from "@project/common";
import ConfirmDialog from "../components/ConfirmDialog";

function CollectionDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [collection, setCollection] = useState<DCollection | null>(null);
  const [proficiencies, setProficiencies] = useState<Record<string, DWordProficiency> | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    const fetchCollectionDetails = async () => {
      try {
        const response = await getCollectionDetails(id as string);
        setCollection(response.data);
        if (response.data.wordList) {
          const data = await getWordProficiency(
            response.data.wordList.map((word: DWord) => word.word)
          );
          setProficiencies(data.data);
        }
      } catch (error) {
        console.error("获取词库详情失败:", error);
      }
    };
    fetchCollectionDetails();
  }, [id]);

  const handleDelete = async () => {
    try {
      await deleteCollection(id as string);
      navigate('/collection');
    } catch (error) {
      console.error("删除词库失败:", error);
    }
  };

  const renderWordList = () => {
    if (!collection || !collection.wordList || collection.wordList.length === 0) {
      return <></>;
    }
    return (
      <>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" component="h2" gutterBottom>
          单词列表
        </Typography>
        <WordList
          wordList={collection.wordList as DWord[]}
          proficiencies={proficiencies}
        />
      </>
    );
  };

  const renderSubCollections = () => {
    if (!collection || !collection.collectionList || collection.collectionList.length === 0) {
      return <></>;
    }
    return (
      <>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5" component="h2" gutterBottom>
          子词库
        </Typography>
        <List>
          {collection.collectionList.map((subCollection) => {
            if (typeof subCollection === "string") {
              return <> </>;
            }
            return (
              <ListItem
                component={Link}
                to={`/collection/${subCollection._id}`}
                key={subCollection._id}
              >
                <ListItemText
                  primary={subCollection.name}
                  secondary={subCollection.description}
                />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  if (!collection) {
    return <Typography>加载中...</Typography>;
  }

  return (
    <AppLayout
      title={collection.name}
      showBackButton={true}
      backPath="/collection"
    >
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" component="h1">
              {collection.name}
            </Typography>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                component={Link}
                to={`/test/${collection._id}`}
                variant="contained"
                color="primary"
                style={{ marginRight: "10px" }}
              >
                测试
              </Button>
              <Button
                component={Link}
                to={`/browse/${collection._id}`}
                variant="contained"
                color="secondary"
                style={{ marginRight: "10px" }}
              >
                阅览
              </Button>
              <Button
                component={Link}
                to={`/plan/create?collectionId=${collection._id}`}
                variant="contained"
                color="secondary"
              >
                创建学习计划
              </Button>
            </Box>
          </Box>
        </Typography>
        <Typography variant="body1" paragraph>
          {collection.description}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          单词数量: {collection.wordCount}
        </Typography>
        {renderWordList()}
        {renderSubCollections()}
        {collection.isPrimary && (
          <Box sx={{ mt: 4, mb: 4, padding: "20em 0 2em 0" }}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => setConfirmDialogOpen(true)}
            >
              删除词库
            </Button>
          </Box>
        )}

        <ConfirmDialog
          open={confirmDialogOpen}
          content={`确定要删除词库 "${collection.name}" 吗？此操作将同时删除所有子词库，且不可恢复。`}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={handleDelete}
          confirmText="删除"
          confirmColor="error"
        />
      </Container>
    </AppLayout>
  );
}

export default CollectionDetails;
