import React from 'react';
import {
  Typography,
} from '@mui/material';
import useManagedCollections from '../hooks/useManagedCollections';
import CommonDialog from './CommonDialog';
import HierachicalSelection from "../components/HierachicalSelection";
import LoadingSpinner from './LoadingSpinner';
import { getPrimaryCollections } from '../services/api';
import { DCollection } from '@project/common';
import { useState, useEffect } from 'react';

interface CollectionSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (selectedCollections: DCollection[]) => void;
  initialSelectedCollections: DCollection[];
}
// Hierachical selection dialog

function CollectionSelectionDialog({
  open,
  onClose,
  onConfirm,
  initialSelectedCollections,
}: CollectionSelectionDialogProps) {

  const [loading, setLoading] = useState(false);
    const {
      loading: collectionLoading,
      getSubCollections,
      getCompactCollections,
      setRoot,
    } = useManagedCollections();
  const [rootCollections, setRootCollections] = useState<DCollection[]>([]);
  const [currentCollections, setCurrentCollections] = useState<DCollection[]>([]);
  const [selectedCollections, setSelectedCollections] = useState<DCollection[]>([]);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        setLoading(true);
        const response = await getPrimaryCollections();
        console.log('get root collections in dialog', response.data);
        const rootCollections: DCollection[] = response.data as DCollection[];
        setRootCollections(rootCollections);
        setCurrentCollections(rootCollections);
        setRoot(rootCollections);
      } catch (error) {
        console.error('获取词库列表失败:', error);
      } finally {
        setLoading(false);
      }
    }
    console.log('open changed', open, rootCollections.length);
    if (open && !rootCollections.length) {
      console.log('fetch root collections');
      fetchCollections();
    }
  }, [open]);

  useEffect(() => {
    setSelectedCollections(initialSelectedCollections);
  }, [initialSelectedCollections]);

  const handleCollectionDialogClose = () => {
    onClose?.()
  }
  const handleCollectionDialogConfirm = () => {
    onConfirm?.(selectedCollections);
  }
  const renderCollection = (c: any) => {
    const collection = c as DCollection;
    return <Typography>{collection.name}</Typography>;
  };

  const getChildren = async (c: DCollection) => {
    return await getSubCollections(c);
  }

  const handleSelectionChange = (selectedIds: Set<string>) => {
    const compactCollections = getCompactCollections(Array.from(selectedIds));
    console.log('compact selected collections', compactCollections);
    setSelectedCollections(compactCollections);
  }

  console.log('render dialog', currentCollections.length);
  return (
      <CommonDialog
        title="选择词库"
        mode="normal"
        open={open}
        onClose={handleCollectionDialogClose}
        onConfirm={handleCollectionDialogConfirm}
      >
        <HierachicalSelection
          items={currentCollections}
          renderItem={renderCollection}
          selectedIds={new Set(selectedCollections.map((c) => c._id))}
          getItemId={(c: any) => c._id}
          hasChildren={(c: any) => c.collectionList?.length > 0}
          getChildren={getChildren}
          onSelectionChange={handleSelectionChange}
        />  
        {(collectionLoading || loading) && <LoadingSpinner size={40} root={false}/>}
      </CommonDialog>
  );
}

export default CollectionSelectionDialog; 