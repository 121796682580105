import axios from 'axios';
import { ApiResponse } from '../types/api';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9000/api';
// get host from app url , without port
const host = new URL(window.location.href).host.split(':')[0];
// get protocol according to url
const API_URL = host.includes("maotuwo.com")
  ? `${window.location.protocol}//${host}/api`
  : `${window.location.protocol}//${host}:9000/api`;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config: any) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});
// 登录相关的API函数

// 导出 api 实例以供通用使用
export { api };

export const getPrimaryCollections = () => api.get('/collection/list');
export const getCollectionDetails = (id: string) => api.get(`/collection/${id}`);
export const getCollections = (collectionIds: string[], populate: boolean = false) =>
  api.post(`/collection/getCollections`, { collectionIds, populate });
export const getCollectionWords = (id: string) => api.get(`/collection/words/${id}`);

export const talkToLLM = (message: any) => api.post('/ai/talk', { message });
export const judgeChineseMeaning = (
  word: string,
  reference: string,
  provided: string
) => api.post("/ai/judgeChineseMeaning", { word, reference, provided });

export const createLearningPlan = (data: any) => api.post(`/plan/create`, data);

export const getTasks = () => api.get('/task/list');
export const getTaskDetails = (id: string) => api.get(`/task/${id}`);

export const getLearningPlans = () => api.get('/plan/list');
export const getPlanDetails = (id: string) => api.get(`/plan/${id}`);

export const getWordProficiency = (words: string[]) => 
  api.post(
    '/word/wordProficiency', 
    { words }
  );
export const submitExamResults = (result: any) => 
  api.post(
    '/test/submitResult', 
    result
  );
export const deleteCollection = (id: string) => api.delete(`/collection/${id}`);
export const deletePlan = (id: string) => api.delete(`/plan/${id}`);