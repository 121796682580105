import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import useSpeechRecognition, { SpeechResult } from '../../hooks/useSpeechRecognition';
import aiService from '../../services/aiService';
import { DWord, WordMode, WordModes, WordInfo, WordResult, WordStatus } from '../../types/word';
import { EXAM_MODES, ExamState, ExamStates } from './types';
import { Utils} from '../../utils/utils'

function getWordSeries(mode: string, words: DWord[]): WordInfo[] {
  // map each element of array to three elements of new array
  // let series = plan.words.slice(0, 3).flatMap(word => {
  let series = words.flatMap(word => {
    if (mode !== EXAM_MODES.MIXED) {
      return [
        { word: word, mode: mode as WordMode }
      ];
    } else {
      return [
        { word: word, mode: WordModes.EXAM_SPELL },
        { word: word, mode: WordModes.EXAM_C2E },
        { word: word, mode: WordModes.EXAM_E2C }
      ];
    }
  }); 
  // shuffle the array if not browse mode
  if (mode !== EXAM_MODES.BROWSE) {
    return series.sort(() => Math.random() - 0.5); 
  }
  return series;
}

export class SpellingChecker {
  private word: DWord;
  private spells: string;
  private frozen: string;
  constructor(word: DWord) {
    this.spells = "";
    this.frozen = "";
    this.word = word;
  }

  splitSpells(spell: string): string[] {
    const result: string[] = [];
    const spokenLetters = spell
      .split(" ")
      .filter((letter) => letter.trim() !== "");
    for (let i = 0; i < spokenLetters.length; i++) {
      if (spokenLetters[i].length === 1 && /[a-zA-Z]/.test(spokenLetters[i])) {
        result.push(spokenLetters[i].toLowerCase());
      } else if (spokenLetters[i].length <= 3) {
        // 语音识别会把一些短词直接识别成不含空格的，比如 A - P - P : app， 这种我们也接受
        // console.log(`get unusual spell: [${spokenLetters[i]}], but it's ok, split it`);
        spokenLetters[i].split("").forEach((letter) => {
          if (/[a-zA-Z]/.test(letter)) {
            result.push(letter.toLowerCase());
          }
        });
      }
    }
    return result;
  }

  mergeSpells = (
    targetWord: string,
    alternativeWord: string,
    spellsWithSpecialCharacters: string,
    deltaWithSpecialCharacters: string = "",
    isFinal: boolean = false
  ): string => {
    console.log(`[check spelling] mergeSpells: target: ${targetWord}, spells: ${this.spells}, frozen: ${this.frozen}, provide: ${spellsWithSpecialCharacters}, delta: ${deltaWithSpecialCharacters}, isFinal: ${isFinal}`);
    // filter out non-letter characters
    const target = targetWord.replace(/[^a-zA-Z]/g, '').toLowerCase();
    const alternative = alternativeWord.replace(/[^a-zA-Z]/g, '').toLowerCase();
    const spells = spellsWithSpecialCharacters.replace(/[^a-zA-Z]/g, '').toLowerCase();
    const delta = deltaWithSpecialCharacters.replace(/[^a-zA-Z]/g, '').toLowerCase();
    let newSpells = '';
    if (this.spells.length === 0) {
      newSpells = spells;
      this.frozen = '';
    } else if (isFinal) {
      if (target.startsWith(this.spells + spells) || alternative.startsWith(this.spells + spells)) {
        newSpells = this.spells + spells;
      } else if (target.startsWith(this.frozen + spells) || alternative.startsWith(this.frozen + spells)) {
        newSpells = this.frozen + spells;
      } else if (target.startsWith(spells) || alternative.startsWith(spells)) {
        newSpells = spells;
      } else {
        newSpells = this.frozen + spells;
      }
      this.frozen = newSpells;
    } else {
      if (target.startsWith(this.spells + spells) || alternative.startsWith(this.spells + spells)) {
        newSpells = this.spells + spells;
      } else if (target.startsWith(this.frozen + spells) || alternative.startsWith(this.frozen + spells)) {
        newSpells = this.frozen + spells;
      } else if (target.startsWith(this.spells + delta) || alternative.startsWith(this.spells + delta)) {
        newSpells = this.spells + delta;
      } else if (target.startsWith(spells) || alternative.startsWith(spells)) {
        newSpells = spells;
        this.frozen = "";
      } else {
        newSpells = this.frozen + spells;
      }
    }
    
    this.spells = newSpells;
    return newSpells;
  };

  updateSpells(newSpell: string, delta: string, isFinal: boolean = false) {
    const spokenLetters = this.splitSpells(newSpell);
    const newSpelledWord = spokenLetters.join("");
    this.mergeSpells(
      this.word.word,
      this.word.alternative || this.word.word,
      newSpelledWord,
      delta,
      isFinal
    );
  }

  checkSpells(): "pass" | "fail" | "continue" {
    const check = (
      target: string | undefined,
      spell: string
    ): "pass" | "fail" | "continue" => {
      if (!target) {
        return "fail";
      }
      const targetCharacters = target.replace(/[^a-zA-Z]/g, '');
      if (spell.length >= targetCharacters.length) {
        return spell === targetCharacters.toLowerCase() ? "pass" : "fail";
      }
      return "continue";
    };
    const result1 = check(this.word.word, this.spells);
    const result2 = check(this.word.alternative, this.spells);

    if (result1 === "pass" || result2 === "pass") {
      this.spells = '';
      this.frozen = '';
      return "pass";
    } else if (result1 === "fail" && result2 === "fail") {
      this.spells = '';
      this.frozen = '';
      return "fail";
    }
    return "continue";
  }

  getSpellsWithSpecialCharacters(): string {
    const getSpells = (
      word: string,
      spells: string
    ): { spells: string; combo: number } => {
      let combo = 0;
      let fullSpell = "";
      let wordSplits = word.split("");
      let spellSplits = spells.split("");
      while (wordSplits.length > 0 && spellSplits.length > 0) {
        const t = wordSplits.shift() as string;
        const s = spellSplits.shift() as string;
        if (s === t?.toLocaleLowerCase()) {
          // 字母相同，将原单词的字母加入最终拼写（因为有可能是大写)
          fullSpell += t;
        } else if (!/[a-zA-Z]/.test(t)) {
          // 单词中是特殊字符，也算对，把特殊字符加入最终拼写
          fullSpell += t;
          spellSplits.unshift(s);
        } else {
          // 拼写与单词对不上了，中断
          spellSplits.unshift(s);
          break;
        }
      }
      combo = fullSpell.length;
      spellSplits.forEach((s) => {
        fullSpell += s;
      });
      console.log(
        `[check spelling] check spell for word[${word}] spell[${spells}]: ${fullSpell}, combo ${combo}`
      );
      return { spells: fullSpell, combo };
    };

    const { spells: spell1, combo: combo1 } = getSpells(
      this.word.word,
      this.spells
    );
    if (!this.word.alternative) {
      return spell1;
    }
    const { spells: spell2, combo: combo2 } = getSpells(
      this.word.alternative,
      this.spells
    );
    return combo1 >= combo2 ? spell1 : spell2;
  }
}

export type WordState = {
  wordTime: number;
  index: number;
  wordInfo: WordInfo | null;
  checker: SpellingChecker | null;
}

const useExamController = (mode: string, onFinished?: (results: WordResult[]) => void) => {

  const [state, setState] = useState<ExamState>(ExamStates.INIT);
  const [wordSeries, setWordSeries] = useState<WordInfo[]>([]);
  const [currentWordState, setCurrentWordState] = useState<WordState>({
    wordTime: 0,
    index: -1,
    wordInfo: null,
    checker: null
  });
  const [spelledWord, setSpelledWord] = useState('');
  const [wordResult, setWordResult] = useState<WordStatus>('none');
  const [showAnswer, setShowAnswer] = useState(false);
  const [examResults, setExamResults] = useState<WordResult[]>([]);
  const examResultsRef = useRef(examResults);

  const speech = useSpeechRecognition();

  const stateRef = useRef(state);
  const nextWordProceedingRef = useRef<boolean>(false);
  const wordStateRef = useRef<WordState>(currentWordState);

  useEffect(() => {
    examResultsRef.current = examResults;
  }, [examResults]);

  const currentWordInfo = useMemo(() => {
    return currentWordState.index >= 0
      ? wordSeries[currentWordState.index]
      : null;
  }, [wordSeries, currentWordState.index]);


  const setExamState = useCallback((state: ExamState) => {
    stateRef.current = state;
    setState(state);
  }, [stateRef, setState]);

  const setPlaying = useCallback((isPlaying: boolean  ) => {
    if (isPlaying) {
      setExamState(ExamStates.PLAYING);
    } else {
      setExamState(ExamStates.READY);
    }
  }, [setExamState]);

  const logStatus = () => {
    console.log(`exam controlller current status:
      state: ${stateRef.current},
      isPlaying: ${isPlaying()},
      isFinished: ${isFinished()},
      currentIndex: ${current().index},
      wordTime: ${current().wordTime},
      spelledWord: ${spelledWord},
      wordResult: ${wordResult},
      showAnswer: ${showAnswer}
    `);
  }

  const setCurrent = useCallback((current: number) => {
    const index =
      wordSeries &&
      wordSeries.length > 0 &&
      current >= 0 &&
      current < wordSeries.length
        ? current
        : -1;
    const wordState = {
      index: index,
      wordInfo: index >= 0 ? wordSeries[index] : null,
      checker: index >=0 ? new SpellingChecker(wordSeries[index].word)
        : null,
      wordTime: index >= 0 ? Date.now() : 0
    }
    wordStateRef.current = wordState;
    setCurrentWordState(wordState);
  }, [wordSeries]);

  const current = useCallback(() => {
    return wordStateRef.current || {
      index: -1,
      wordInfo: null,
      wordTime: 0,
      checker: null
    };
  }, [wordStateRef]);

  const isFinished = useCallback(() => {
    return stateRef.current === ExamStates.FINISHED;
  }, [stateRef]);

  const isPlaying = useCallback(() => {
    return stateRef.current === ExamStates.PLAYING;
  }, [stateRef]);

  const startListening = useCallback(() => {
    if (mode === EXAM_MODES.BROWSE) {
      return;
    }
    const { wordInfo } = current();
    let lang = 'en-US';
    if (
      wordInfo &&
      wordInfo.mode === EXAM_MODES.E2C
    ) {
      lang = "zh-CN";
    }
    console.log(`[speech] voiceLang: ${lang}, current word mode [${wordInfo?.word.word || 'unknown'} - ${wordInfo?.mode}]`);
    speech.startListening(lang);
  }, [speech, current, mode]);

  const stopListening = useCallback(() => {
    speech.stopListening();
  }, [speech]);

  const reset = () => {
    console.log(`[exam controller] reset`);
    const results: WordResult[] = [];
    setExamState(ExamStates.INIT);
    setCurrent(-1);
    setSpelledWord('');
    setWordResult('none');
    setShowAnswer(false);
    setExamResults(results); 
    nextWordProceedingRef.current = false;
    examResultsRef.current = results;
    stopListening();
  }

  const start = () => {
    console.log(`[exam controller] start`);
    if (stateRef.current !== ExamStates.INIT) {
      console.log('start exam controller failed, state is not init, please reset first');
      return;
    }
    setExamState(ExamStates.READY);
  }

  const stop = () => {
    console.log(`[exam controller] stop`);
    setPlaying(false);
    stopListening();
  }

  const startWithWords = (words: DWord[]) => {
    console.log(`[exam controller] set words: ${words.length}`);
    setWordSeries(prev => {
      if (!prev || prev.length === 0) {
        return getWordSeries(mode, words);
      }
      return prev;
    });
    start();
  } 

  const finishExam = useCallback(async () => {
    setCurrent(-1);
    console.log('finish exam, stop listening');
    stopListening();
    setExamState(ExamStates.FINISHED);
    onFinished?.(examResultsRef.current);
  }, [onFinished, setCurrent, setExamState, stopListening]);

  const prevWord = useCallback(() => {
    const { index } = current();
    console.log(`[prevWord] current: ${index}, wordSeries length: ${wordSeries.length}`);
    if (index <= 0) {
      return;
    }
    setSpelledWord('');
    setShowAnswer(false);
    setWordResult('none');
    setCurrent(index - 1);
    startListening();
  }, [current, wordSeries.length, setCurrent, startListening]);  

  const nextWord = useCallback(() => {
    setSpelledWord('');
    setShowAnswer(false);
    setWordResult('none');
    const {index} = current();
    console.log(`[nextWord] current: ${index}, wordSeries length: ${wordSeries.length}`);
    if (index >= wordSeries.length - 1) {
      console.log('finish exam, currentIndex:', index, ', wordSeries length:', wordSeries.length );
      return finishExam();
    }
    setCurrent(index + 1);
    startListening();
  }, [current, wordSeries.length, setCurrent, startListening, finishExam]);  

  const delayedNextWord = useCallback(() => {
    if (nextWordProceedingRef.current) {
      return;
    }
    nextWordProceedingRef.current = true;
    setTimeout(() => {
      nextWordProceedingRef.current = false;
      nextWord();
    }, mode === EXAM_MODES.BROWSE ? 500 : 2000);
  }, [nextWord, mode]); 

  const pushExamResult = useCallback((correct: boolean) => {
    const { wordInfo, wordTime } = current();
    if (!wordInfo) {
      return;
    }
    // get time spent in seconds
    const timeSpent = (Date.now() - wordTime) / 1000;
    const result: WordResult = {
      word: wordInfo.word.word,
      mode: wordInfo.mode,
      correct: correct,
      timeSpent: timeSpent,
    };
    // TODO remove hack code
    // result.correct = true;  
    // result.timeSpent = Math.random() * 5 + 1;
    setExamResults((prev) => {
      const newResults = [
        ...prev,
        result
      ];
      examResultsRef.current = newResults;
      return newResults;
    });
  }, [current]);

  const correctAndNext = useCallback(() => {
    if (nextWordProceedingRef.current) {
      return;
    }
    setWordResult('success');
    setShowAnswer(true);
    stopListening();
    pushExamResult(true);
    delayedNextWord();
  }, [stopListening, pushExamResult, delayedNextWord]);

  const wrong = useCallback(() => {
    if (nextWordProceedingRef.current) {
      return;
    }
    setWordResult('failure');
    setSpelledWord('');
    setTimeout(() => {
      setWordResult('none');
      startListening();
    }, 1000);
  }, [startListening]);

  const wrongAndNext = useCallback(() => {
    if (nextWordProceedingRef.current) {
      return;
    }
    setWordResult('failure');
    setShowAnswer(true);
    setSpelledWord('');
    stopListening();
    pushExamResult(false);
    delayedNextWord();
  }, [setWordResult, setShowAnswer, stopListening, pushExamResult, delayedNextWord]);

  const handleReadyGo = useCallback(() => {
    console.log('handleReadyGo');
    setExamState(ExamStates.PLAYING);
    nextWord();
  }, [nextWord, setExamState]);
  
  const handleTimeUp = useCallback(() => {
    wrongAndNext();
  }, [wrongAndNext]);

  const handlePrevious = useCallback(() => {
    if (!isPlaying()) {
      return;
    }
    // if (mode !== EXAM_MODES.BROWSE) {
    //   return;
    // }
    prevWord();
  }, [prevWord, isPlaying]);

  const handleNext = useCallback(() => {
    if (!isPlaying()) {
      return;
    }
    // if (mode !== EXAM_MODES.BROWSE) {
    //   return;
    // }
    if (mode !== EXAM_MODES.BROWSE) {
      pushExamResult(false);
    }
    nextWord();
  }, [isPlaying, mode, nextWord, pushExamResult]);

  const checkSpelling = useCallback((result: SpeechResult, word: DWord) => {
    const { checker } = current();
    if (!checker) {
      return;
    }
    const spoken = result.transcript.trim().toLocaleLowerCase();
    const delta = result.delta.trim().toLocaleLowerCase();
    console.log(`[check spelling] spoken: ${spoken}, delta: ${delta}`);
    checker.updateSpells(spoken, delta, result.isFinal);
    setSpelledWord(checker.getSpellsWithSpecialCharacters());
    const status = checker.checkSpells();
      
    if (status === 'pass') {
      console.log('拼写正确！');
      return correctAndNext();
    } else if (status === 'fail') {
      console.log('拼写错误！');
      return wrong();
    }
  }, [correctAndNext, current, wrong]);

  const checkMeaning = useCallback(async (result: SpeechResult, word: DWord) => {
    const spoken = result.transcript.trim();
    if (spoken.trim().length === 0) {
      return;
    }
    // 
    console.log(`check meaning, user(${spoken}), target(${word.word}))`);
    if (word.translation.includes(spoken)) {
      return correctAndNext();
    } else {
      // const result = await aiService.checkChineseTranslation(word, spoken);
      const result = await aiService.judgeChineseMeaning(word.word, word.translation, spoken);
      if (result) {
        console.log('AI 判定释义正确！');
        return correctAndNext();
      } else {
        console.log('AI 判定释义错误！');
        return wrong();
      }
    }
  }, [correctAndNext, wrong]);

  const checkSpoken = useCallback((result: SpeechResult, word: DWord) => {
    if (!result.isFinal) {
      return;
    }
    let spokenWord = result.transcript.trim().replace(/[^a-zA-Z]/g, '').toLowerCase();
    if (spokenWord.length === 0) {
      return;
    } 
    const targetWord = word.word.replace(/[^a-zA-Z]/g, '').toLowerCase();
    const targetAlternative = word.alternative ? word.alternative.replace(/[^a-zA-Z]/g, '').toLowerCase() : '';
    console.log(`check spoken, user(${spokenWord}), target(${targetWord}), alternative(${targetAlternative}))`);
    if (spokenWord === targetWord || spokenWord === targetAlternative) {
      console.log('正确！');
      return correctAndNext();
    } else {
      console.log('错误！');
      return wrong();
    }
  }, [correctAndNext, wrong]);

  const checkAnswer = useCallback((result: SpeechResult) => {
    const { wordInfo } = current();
    console.log(`[check answer] wordInfo: ${wordInfo}`);
    if (!wordInfo) {
      return;
    }
    switch (wordInfo.mode) {
      case EXAM_MODES.SPELL:
        checkSpelling(result, wordInfo.word);
        break;
      case EXAM_MODES.C2E:
        checkSpoken(result, wordInfo.word);
        break;
      case EXAM_MODES.E2C:
        checkMeaning(result, wordInfo.word);
        break;
      default:
    }
  }, [current, checkSpelling, checkSpoken, checkMeaning]);

  const handleVoiceCommand = useCallback((result: SpeechResult) => {
    console.log('handleVoiceCommand');
    const cmd = result.transcript.toLowerCase().trim();
    switch (cmd) {
      case 'next':
        nextWord();
        break;
      case 'previous':
        handlePrevious();
        break;
      case 'pause':
        setPlaying(false);
        break;
      case 'play':
        setPlaying(true);
        break;
      default:
        console.log(`mode: ${mode}`);
        if (mode !== EXAM_MODES.BROWSE) {
          checkAnswer(result);
        }
    }
  }, [checkAnswer, handlePrevious, mode, nextWord, setPlaying]);

  const handleSpeechResult = useCallback((r: SpeechResult) => {
    handleVoiceCommand(r);
  }, [handleVoiceCommand]);

  useEffect(() => {
    speech.setOnResultCallback(handleSpeechResult);
  }, [handleSpeechResult, speech]);


  return {
    state,
    wordSeries,
    currentWordInfo,
    currentWordState,
    wordResult,
    spelledWord,
    showAnswer,
    examResults,
    isPlaying,
    isFinished,
    reset,
    start,
    startWithWords,
    stop,
    handleTimeUp,
    handlePrevious,
    handleNext,
    handleReadyGo,
    logStatus,
  };
};

export default useExamController;