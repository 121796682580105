import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  mask: {
    zIndex: 9999,
    height: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  parent: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

const LoadingSpinner: React.FC<{
  message?: string;
  root?: boolean;
  size?: number;
}> = ({ message = "加载中...", root = true, size = 60 }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.mask} ${root ? classes.root : classes.parent}`}>
      <CircularProgress size={size} thickness={4} />
      <Typography variant="h6" className={classes.text}>
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingSpinner;
