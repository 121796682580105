import { createContext } from 'react';

interface LoadingContextType {
  setIsLoading: (isLoading: boolean) => void;
  setLoadingMessage: (message: string) => void;
}

const LoadingContext = createContext<LoadingContextType>({
  setIsLoading: () => {},
  setLoadingMessage: () => {},
});

export default LoadingContext;
