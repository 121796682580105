import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Button } from '@mui/material';
import { getPlanDetails } from '../services/api';
import useLoading from '../hooks/useLoading';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useRenderTracker } from '../hooks/useRenderTracker';
import { EXAM_MODES } from '../components/WordExam/types';
import WordExam, { WordExamRef } from '../components/WordExam';
import { DPlan } from '@project/common';

function BrowsePlan() {
  const { id} = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [plan, setPlan] = useState<DPlan | null>(null);
  const [finished, setFinished] = useState(false);
  const backpath = searchParams.get('backpath') || location.state?.backpath || '/plan';
  const navigate = useNavigate();
  const examRef = useRef<WordExamRef>(null);


  const { showLoading, hideLoading } = useLoading();

  // useRenderTracker(
  //   "BrowsePlan",
  //   { id },
  //   {
  //     isPlaying,
  //     finished,
  //     wordSeries,
  //     currentWordInfo,
  //     wordTime,
  //     currentIndex,
  //   }
  // );

  useEffect(() => {
    showLoading();
    const fetchWords = async () => {
      try {
        const response = await getPlanDetails(id as string);
        setPlan(response.data as DPlan);
        examRef.current?.startWithWords(response.data.words);
      } catch (error) {
        console.error('获取单词列表失败:', error);
      } finally {
        hideLoading();
      }
    };
    fetchWords();
  }, [id]);


  const handleRetry = () => {
    examRef.current?.reset();
    examRef.current?.start();
  }

  const handleFinished = () => {
    console.log('handleFinished');
    setFinished(true);
  }

  const handleReturn = () => {
    console.log('handleReturn', backpath);
    navigate(backpath);
  }; 
  
  // console.log('render PlanExam');
  // examRef.current?.logStatus();

  const renderResultPage = () => {
    if (finished) {
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <Button variant="contained" color="primary" onClick={handleRetry}>
            再来一次
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleReturn}>
            返回
          </Button>
        </div>
      </Container>
    );
    } else {
      return <Container maxWidth="xl" disableGutters></Container>;
    }
  };
  
  return (
    <Container maxWidth="xl" disableGutters>
      <WordExam
        ref={examRef}
        title={plan?.name}
        mode='browse'
        showBack={true}
        onBack={handleReturn}
        onFinished={handleFinished}
        resultPage={renderResultPage()}
      />
    </Container>
  );
}
export default BrowsePlan;