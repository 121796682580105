
import { useState, useEffect } from 'react';
import { DCollection } from '@project/common'
import { getCollections } from '../services/api';

const useManagedCollections = () => {

  const [rootCollections, setRootCollections] = useState<DCollection[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCollections = async (ids: string[]): Promise<DCollection[]> => {
    const response = await getCollections(ids);
    const collections = response.data as DCollection[];
    console.log('fetchCollections', response.data, collections);
    return collections;
  }
  // judge if target collection is in the collection list, or their sub collections
  const contains = (cList: DCollection[], targetId: string): DCollection | undefined => {
    // judge if collection._id matches id in collections
    let candidate = cList.find(c => c._id === targetId);
    if (candidate) {
      return candidate;
    }
    // test its sub collection recursively
    for (const s of cList) {
      if (s.collectionList.length > 0 && typeof s.collectionList[0] === 'object') {
        candidate = contains(s.collectionList as DCollection[], targetId);
        if (candidate) {
          return candidate;
        }
      }
    }
    return undefined;
  }

  const managedCollection = (target: DCollection | string): DCollection | undefined => {
    let targetId = typeof target === 'string' ? target : target._id;
    return contains(rootCollections, targetId);
  }

  const setRoot = (collections: DCollection[] | string[]) => {
    setLoading(true);
    const fetchRootCollections = async (ids: string[]) => {
      console.log("managed root collections starting fetching: ", ids);
      const collections = await fetchCollections(ids);
      setRootCollections(collections);
      setLoading(false);
    }
    if (collections.length > 0 && typeof collections[0] === 'string') {
      fetchRootCollections(collections as string[]);
    } else {
      console.log('set root collections directly, no neeed to fetch', collections);
      setRootCollections(collections as DCollection[]);
      setLoading(false);
    }
  }

  const getSubCollections = async (collection: DCollection) => {
    setLoading(true);
    console.log('managed collection before fetch', rootCollections);
    const managed: DCollection | undefined = managedCollection(collection);
    console.log('got managed collection', managed);
    let subCollections: DCollection[] = [];
    if (managed) {
      if (managed.collectionList.length !== 0 && typeof managed.collectionList[0] === 'string') {
        console.log('manage collection has string[] subCollectios, fetch it', managed.collectionList);
        subCollections = await fetchCollections(managed.collectionList as string[]);
        managed.collectionList = subCollections;
      }
      subCollections = managed.collectionList as DCollection[];
    }
    console.log('managed collection after fetch', rootCollections);
    setLoading(false);
    return subCollections;
  }

  // get as less collections as possible
  const getCompactCollections = (ids: string[]) => {
    let collections: DCollection[] = []
    ids.forEach((id) => {
      let c = managedCollection(id);
      if (c) {
        collections.push(c);
      }
    });
    let targetCollections: DCollection[] = [];
    for (const c of collections) {
      if (c.isPrimary) {
        targetCollections.push(c);
        continue;
      } 
      // 1. if c is a sub collection of targetCollections, ignore it
      if (contains(targetCollections, c._id)) {
        continue;
      }
      // 2. filter out collections in targetCollections, which are sub collections of c
      targetCollections = targetCollections.filter((tc) => !contains([c], tc._id));
      // 3. push c into targetCollections
      targetCollections.push(c);
    }
    return targetCollections;
  }

  return {
    loading,
    getSubCollections,
    setRoot,
    getCompactCollections
  };
};

export default useManagedCollections;
