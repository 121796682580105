import { useContext } from 'react';
import LoadingContext from '../contexts/LoadingContext';

interface UseLoadingReturn {
  showLoading: (message?: string) => void;
  hideLoading: () => void;
}

const useLoading = (): UseLoadingReturn => {
  const { setIsLoading, setLoadingMessage } = useContext(LoadingContext);

  const showLoading = (message: string = '加载中...') => {
    setIsLoading(true);
    setLoadingMessage(message);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  return { showLoading, hideLoading };
};

export default useLoading;
