"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
class Utils {
    /**
     * 将原始翻译文本转换为结构化的翻译数组
     * @param {string} rawTranslation - 原始翻译文本
     * @returns {Array<[string|undefined, string]>} - 结构化的翻译数组
     */
    static parseTranslation(rawTranslation) {
        // 按换行符分割原始文本
        const lines = rawTranslation.split('\n').filter(line => line.trim() !== '');
        return lines.map(line => {
            // 使用正则表达式匹配词性注解
            const match = line.match(/^([a-z]+\.\s*)+/);
            if (match) {
                // 如果找到词性注解，将其提取出来并去除多余空格
                const partOfSpeech = match[0].trim();
                // 提取解释部分并去除首尾空格
                const explanation = line.slice(match[0].length).trim();
                return [partOfSpeech, explanation];
            }
            else {
                // 如果没有找到词性注解，则词性为 undefined
                return [undefined, line.trim()];
            }
        });
    }
    static formatDate(date) {
        if (typeof date === 'string' || typeof date === 'number') {
            date = new Date(date);
        }
        if (!date) {
            return 'Unknown Date';
        }
        return date.toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric' });
    }
    static formatTime(date) {
        if (typeof date === 'string' || typeof date === 'number') {
            date = new Date(date);
        }
        if (!date) {
            return 'Unknown Time';
        }
        return date.toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }
}
exports.Utils = Utils;
