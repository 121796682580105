import React from 'react';
import { List, ListItem, ListItemText, Box } from '@mui/material';
import ProficiencyBar from './ProficiencyBar';
import styles from './WordList.module.css';
import { DWord, DWordProficiency } from '@project/common';
import { Typography } from '@mui/material';
import { responsive } from '../utils/responsive';

interface WordListProps {
  wordList: DWord[];
  proficiencies?: Record<string, DWordProficiency> | null;
  showDetails?: boolean;
}

const WordList = ({ wordList, proficiencies = null, showDetails = true }: WordListProps) => {
  console.log('proficiencies', proficiencies);
  console.log('wordList', wordList);
  return (
    <List>
      {wordList.map((word) => (
        <ListItem key={word._id} className={styles.listItem}>
          <ListItemText
            primary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" className={styles.word}>
                  {word.word}
                  {word.alternative ? (
                    <Typography component="span" className={styles.alternative}>
                      {" "}
                      / {word.alternative}{" "}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
                <ProficiencyBar
                  proficiency={proficiencies?.[word.word]?.proficiency || 0}
                />
              </Box>
            }
            secondary={
              showDetails && (
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: responsive(1),
                  }}
                >
                  {word.usPhone && (
                    <Typography
                      component="span"
                      variant="body2"
                      className={styles.phonetic}
                    >
                      /{word.usPhone}/
                    </Typography>
                  )}
                  <Typography
                    component="span"
                    variant="body2"
                    className={styles.translation}
                  >
                    {word.translation.length > 30
                      ? word.translation.slice(0, 30) + "..."
                      : word.translation}
                  </Typography>
                </Box>
              )
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default WordList;
